import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
//SoalText
import SoalText from "../pages/HalamanSoal/SoalText/soal_text"


import SoalRating from "../pages/HalamanSoal/SoalRating/soal_rating"
import SoalMatrix from "../pages/HalamanSoal/SoalMatrixQuestion"
import SoalQuestion from "../pages/HalamanSoal/SoalRankingQuestion"
import SoalMultipleChoice from "../pages/HalamanSoal/SoalMultipleChoice"
import SoalGambar from "../pages/HalamanSoal/SoalGambar/soal_gambar"
import SoalBipolar from "../pages/HalamanSoal/SoalBipolar"
import SoalBei from "../pages/HalamanSoal/SoalBei"
import DetailSoalInBasketEmail from "../pages/HalamanSoal/SoalInBasket/detailSoal"
import SoalInBasket from "../pages/HalamanSoal/SoalInBasket"
import SoalListAlatTes from "../pages/ListAlatTest"
import Soal from '../pages/HalamanSoal/index'
import SoalJenisKompetensi from '../pages/HalamanSoal/SoalJenisKompetansi'
import Capture from '../pages/Capture/index'
//Proktoring
import Proktoring from "pages/Proktoring/proktoring"
import PreviewSoal from "pages/PreviewSoal/PreviewSoal"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/capture", component: <Capture /> },

  // //profile
  { path: "/profile", component: <UserProfile /> },
  { path: "/soalText", component: <SoalText /> },
  { path: "/SoalRating", component: <SoalRating /> },
  { path: "/soalMatrix", component: <SoalMatrix /> },
  { path: "/soalQuestion", component: <SoalQuestion /> },
  { path: "/soalMultipleChoice", component: <SoalMultipleChoice /> },
  { path: "/soalGambar", component: <SoalGambar /> },
  { path: "/soalBipolar", component: <SoalBipolar /> },
  { path: "/soalBei", component: <SoalBei /> },
  { path: "/DetailSoalInBasketEmail", component: <DetailSoalInBasketEmail /> },
  { path: "/SoalInBasket", component: <SoalInBasket /> },
  { path: "/SoalJenisKompetensi", component: <SoalJenisKompetensi /> },
  { path: "/SoalInBasket/:detail", component: <SoalInBasket /> },
  { path: "/soalListAlatTes", component: <SoalListAlatTes /> },
  { path: "/Soal/:id", component: <Soal /> },



  {
    path: "/",
    exact: true,
    component: < Navigate to="/dashboard" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/proktoring/:nip/:pass/:idJadwal", component: <Proktoring /> },
  { path: "/preview/:nip/:pass/:idsoal", component: <PreviewSoal /> }

]

export { authProtectedRoutes, publicRoutes }

