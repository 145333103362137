import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationLink,
  PaginationItem,
  CardHeader,
  Collapse,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
import classnames from "classnames"
const MemoizedCountdownTimer = React.memo(CountdownTimer)

const tipeA = props => {
  const idAlattest = props.idAlattest
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const waktu = props.waktu
  const customLayout = props.customLayout
  const subTest = props.subTest
  const showTimer = props.showTimer
  const showInstruksi = props.showInstruksi
  const isExpand = props.isExpand
  const onExpandToggle = props.onExpandToggle
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionspetunjuk, setSelectedOptionspetunjuk] = useState([])
  const [error, setError] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState()
  const [page, setPage] = useState(1)
  const [data, setdata] = useState([])
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)
  const [tandaiState, setTandaiState] = useState([])
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const totalPage = Math.ceil(dataPage / itemsPerPage)
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage
  const [totalJawaban, setTotalJawaban] = useState(0)
  const [answeredCounts, setAnsweredCounts] = useState({})
  const [totalAnswered, setTotalAnswered] = useState(0)
  const [totalJawabanByQuestion, setTotalJawabanByQuestion] = useState({})
  const [TotalSemuaJawaban, setTotalSemuaJawaban] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    id_aspekat,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    const updatedSelectedOptions = [...selectedOptions]
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      id_aspekat,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)

    // Update answeredQuestions set
    const updatedAnsweredQuestions = new Set(answeredQuestions)
    updatedAnsweredQuestions.add(id_msoal) // Add the question ID to the set

    setAnsweredQuestions(updatedAnsweredQuestions)

    // Update totalJawaban with the size of answeredQuestions set
    setTotalJawaban(updatedAnsweredQuestions.size)

    // Update TotalSemuaJawaban
    setTotalSemuaJawaban(updatedAnsweredQuestions.size)

    // Log totalJawaban
    console.log("Total Jawaban:", updatedAnsweredQuestions.size)
  }
  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    id_aspekat,
    skala,
    index
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptionspetunjuk]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      id_aspekat,
      skala: skala[index],
      index,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptionspetunjuk(updatedSelectedOptions)
  }

  useEffect(() => {
    if (selectedOptions.length > 0) {
      // Swal.fire({
      //   title: "Proses data...",
      //   allowOutsideClick: false,
      //   onBeforeOpen: () => {
      //     Swal.showLoading()
      //   },
      //   showConfirmButton: false,
      // })

      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )
      var formdata = new FormData()
      formdata.append("id_jadwal", DecodeJwt.id_jadwal)
      formdata.append("nip", DecodeJwt.nip)
      formdata.append("id_alattest", idAlattest)
      formdata.append("id_subtest", idSubtest)
      formdata.append("data", JSON.stringify(selectedOptions))

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          if (error) {
            // Swal.fire({
            //   title: "skala sudah terpilih cari skala lain",
            //   icon: "error",
            //   timer: 3000,
            //   showConfirmButton: false,
            // })
          } else {
            // Swal.fire({
            //   title: "Proses selesai!",
            //   icon: "success",
            //   timer: 1000,
            //   showConfirmButton: false,
            // })
          }
        })
        .catch(error => {
          console.log("error", error)
          // Menutup SweetAlert jika terjadi kesalahan
          Swal.fire({
            title: "Terjadi kesalahan",
            icon: "error",
          })
        })
    } else {
      // Swal.fire({
      //   title: "Proses data...",
      //   allowOutsideClick: false,
      //   onBeforeOpen: () => {
      //     Swal.showLoading()
      //   },
      //   showConfirmButton: false,
      // })
      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer" + localStorage.getItem("access_token")
      )

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/viewJawaban?" +
          "id_subtest=" +
          idSubtest +
          "&id_alattest=" +
          idAlattest +
          "&id_jadwal=" +
          DecodeJwt.id_jadwal +
          "&nip=" +
          DecodeJwt.nip,
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          const responseData = JSON.parse(result)
          if (responseData.meta.code == 200) {
            setSelectedOptions(responseData.data[0])
            setkondisiSoal(false)
            // Swal.fire({
            //   title: "Proses selesai!",
            //   icon: "success",
            //   timer: 1000,
            //   showConfirmButton: false,
            // })
          } else {
            setkondisiSoal(true)
            // Swal.fire({
            //   title: "Proses selesai!",
            //   icon: "success",
            //   timer: 1000,
            //   showConfirmButton: false,
            // })
          }
        })
        .catch(error => {
          setkondisiSoal(true)
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        })
    }
  }, [selectedOptions])

  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
      setdata(soalPetunjuk)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
      setdata(dataSoal)
    }
  }, [kondisiSoal])

  const handleTandaiSoal = () => {
    setTandaiState(prevState => {
      const updatedState = [...prevState]
      if (!updatedState.includes(page - 1)) {
        updatedState.push(page - 1)
        Swal.fire({
          title: "Soal Nomor " + page + " Ditandai",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        })
      } else {
        updatedState.splice(updatedState.indexOf(page - 1), 1)
      }
      return updatedState
    })
  }

  useEffect(() => {
    // Update totalJawaban berdasarkan jumlah soal yang tampil
    if (data && data.length > 0) {
      setTotalJawaban(data.length)
    }
  }, [data])

  useEffect(() => {
    // Update totalJawaban berdasarkan jumlah soal yang tampil
    if (data && data.length > 0) {
      setTotalJawaban(data.length)
    }
  }, [selectedOptions])

  useEffect(() => {
    // Create an object to keep track of total answers for each question number
    const totalAnswersByQuestion = _.countBy(selectedOptions, "no_soal")

    // Optionally, you can log or set this value to the state if needed
    console.log("Total Jawaban per Nomor Soal:", totalAnswersByQuestion)

    // If you want to set this information in a state for further use
    setTotalJawabanByQuestion(totalAnswersByQuestion)
  }, [selectedOptions])

  useEffect(() => {
    const counts = {}

    selectedOptions.forEach(option => {
      const { no_soal } = option
      if (!counts[no_soal]) {
        counts[no_soal] = 0
      }
      counts[no_soal] += 1
    })

    const total = Object.keys(counts).length

    setAnsweredCounts(counts)
    setTotalSemuaJawaban(total)

    // Log the data using console.table
    const tableData = Object.entries(counts).map(([questionNo, count]) => ({
      Nomor: questionNo,
      TotalJawaban: count,
      TotalSemuaJawaban: total,
    }))

    // console.table(tableData)
  }, [selectedOptions])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  const [openAccordions, setOpenAccordions] = useState(isExpand)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  const [soalState, setSoalState] = useState(9)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const expand = () => {
    setSoalState(soalState === 9 ? 12 : 9)
    setPetunjukStatus(!petunjukStatus)
    onExpandToggle() // Notify parent to update state
  }

  useEffect(() => {
    setPage(currentIndex + 1)
    // console.log("INDEX HALAMAN SAAT INI: ", currentIndex)
  }, [currentIndex])

  const nextPage = page => {
    setPage(page + 1)
    setCurrentIndex(page)
  }

  const prevPage = page => {
    setPage(page - 1)
    setCurrentIndex(page - 2)
  }

  return (
    <div>
      <Row>
        {error ? (
          <div className="alert alert-danger w-100 d-flex align-items-center shake">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Skala sudah di Inputkan, pilih skala lain atau ubah skala
            </p>
          </div>
        ) : (
          !showInstruksi && (
            <div className="accordion m-3" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames(
                      "accordion-button",
                      "fw-medium",
                      "fs-1",
                      {
                        collapsed: !openAccordions,
                      }
                    )}
                    type="button"
                    onClick={toggleAccordion}
                    style={{ cursor: "pointer" }}
                  >
                    Instruksi
                  </button>
                </h2>

                <Collapse
                  isOpen={openAccordions}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <CardBody className="bg-light rounded rounded-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subTest?.instruksi,
                        }}
                      />
                    </CardBody>
                  </div>
                </Collapse>
              </div>
            </div>
          )
        )}

        {waktu && kondisiSoal === false && !showTimer && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>

      <Row>
        <Col xl={soalState}>
          {data.slice(startIndex, endIndex).map((item, outerIndex) => (
            <Card
              className="shadow shadow-lg d-flex flex-column mh-25 full-height"
              key={outerIndex}
              style={customLayout != null ? customLayoutCardSoal : null}
            >
              <CardHeader className="d-flex border-bottom bg-primary border-4 text-white justify-content-between align-items-center">
                <div className="flex-grow-1">
                  <p
                    style={customLayout != null ? customLayoutSoal : null}
                    className="card-title m-0"
                    dangerouslySetInnerHTML={{
                      __html: item.soal,
                    }}
                  ></p>
                </div>

                <div>
                  <button
                    type="button"
                    onClick={() => {
                      expand()
                    }}
                    className="btn noti-icon text-white"
                    style={{ marginRight: "10px" }}
                  >
                    <i
                      className="bx bx-fullscreen"
                      style={{ color: "white" }}
                    />
                  </button>

                  <Button
                    className="btn bg-danger"
                    color={
                      tandaiState.includes(outerIndex) ? "danger" : "primary"
                    }
                    onClick={handleTandaiSoal}
                  >
                    {tandaiState.includes(outerIndex)
                      ? "Hapus Penandaan"
                      : "Tandai"}
                  </Button>
                </div>
              </CardHeader>

              <CardBody style={{ flex: 1, overflow: "auto" }}>
                <table className="table table-striped">
                  <thead>
                    <tr className="text-start fw-bold">
                      <th></th>
                      {JSON.parse(item.skala).map((item2, index2) => (
                        <th key={index2}>
                          {" "}
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item2,
                            }}
                          ></div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.pilihan_jawaban.map((item3, innerIndex) => (
                      <tr className="text-start fw-bold" key={innerIndex}>
                        <td>
                          <div
                            style={
                              customLayout != null ? customLayoutJawaban : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item3.pilihan,
                            }}
                          ></div>
                        </td>
                        {JSON.parse(item3.value).map((item4, index4) => (
                          <td key={index4}>
                            <input
                              type="radio"
                              className="form-check-input border border-2 border-primary"
                              name={`pilihan_${outerIndex}${innerIndex}`}
                              onClick={() => {
                                if (kondisiSoal) {
                                  handleRadioChangePetunjuk(
                                    item.id_msoal,
                                    item.mt_idsubtest,
                                    item.no_soal,
                                    item.soal,
                                    item3.id_soal_jawaban,
                                    item3.pilihan,
                                    item4,
                                    item3.id_aspekat,
                                    JSON.parse(item.skala),
                                    index4,
                                    item3.id_indikator
                                  )
                                } else {
                                  handleRadioChange(
                                    item.id_msoal,
                                    item.mt_idsubtest,
                                    item.no_soal,
                                    item.soal,
                                    item3.id_soal_jawaban,
                                    item3.pilihan,
                                    item4,
                                    item3.id_aspekat,
                                    JSON.parse(item.skala),
                                    index4,
                                    item3.id_indikator
                                  )
                                }
                              }}
                              checked={
                                kondisiSoal
                                  ? selectedOptionspetunjuk.some(
                                      option =>
                                        option.id_msoal === item.id_msoal &&
                                        option.id_soal_jawaban ===
                                          item3.id_soal_jawaban &&
                                        option.index === index4
                                    )
                                  : selectedOptions.some(
                                      option =>
                                        option.id_msoal === item.id_msoal &&
                                        option.id_soal_jawaban ===
                                          item3.id_soal_jawaban &&
                                        option.index === index4
                                    )
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
              <Row>
                <Col lg="12">
                  <ul className="pagination pagination-rounded justify-content-center mb-10 d-flex">
                    <PaginationItem
                      disabled={page === 1}
                      style={{
                        margin: "0 5px",
                        flex: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PaginationLink
                        className="fs-5"
                        style={{
                          borderRadius: "4px",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        previous
                        onClick={e => {
                          e.preventDefault()
                          prevPage(page)
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            transform: "rotate(180deg)",
                          }}
                        >
                          &#10140;
                        </span>{" "}
                        Sebelumnya
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      disabled={page === totalPage}
                      style={{
                        margin: "0 5px",
                        flex: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <PaginationLink
                        className="fs-5"
                        style={{
                          borderRadius: "4px",
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        next
                        onClick={e => {
                          e.preventDefault()
                          nextPage(page)
                        }}
                      >
                        Selanjutnya &#10140;
                      </PaginationLink>
                    </PaginationItem>
                  </ul>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
        <Col xl={3}>
          {petunjukStatus && (
            <HistorySoal
              data={data}
              selectedOptions={
                kondisiSoal ? selectedOptionspetunjuk : selectedOptions
              }
              setSoal={setPage}
              tandaiState={tandaiState}
              currentIndex={currentIndex}
              setCurrentIndex={setCurrentIndex}
            />
          )}
        </Col>
      </Row>
      {(isLastPage || statusTImer === true) && (
        <Row>
          <SaveJawaban
            idAlattest={idAlattest}
            idSubtest={idSubtest}
            id_jadwal={DecodeJwt.id_jadwal}
            nip={DecodeJwt.nip}
            id_asesi={DecodeJwt.id_jadwal_asesi}
            kondisiSoal={kondisiSoal}
            setKondisiSoal={setkondisiSoal}
            setPage={setPage}
            statusTImer={statusTImer}
            dataSoal={dataSoal}
            soalPetunjuk={soalPetunjuk}
            selectedOptions={selectedOptions}
            totalJawaban={totalJawaban}
            TotalSemuaJawaban={TotalSemuaJawaban}
          />
        </Row>
      )}
    </div>
  )
}

export default tipeA
