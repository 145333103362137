import React from "react"
import { Navigate } from "react-router-dom"

const Authmiddleware = props => {
  // Get the current date and time
  const currentDateTime = new Date()
  const formattedDateTime = currentDateTime.toISOString()
  if (
    localStorage.getItem("expired_at") <= formattedDateTime ||
    !localStorage.getItem("expired_at") || !localStorage.getItem('access_token')
  ) 
  {
    return (
      <Navigate to={{ pathname: "/login", state: { from: props.location } }} />
    )
  }
  return <React.Fragment>{props.children}</React.Fragment>
}

export default Authmiddleware
