import React, { useEffect, useState } from "react"

import SoalMultipleChoice from "./SoalMultipleChoice/index"
import SoalBipolar from "./SoalBipolar/index"
import SoalMatrix from "./SoalMatrixQuestion/index"
import SoalText from "./SoalText/soal_text"
import SoalBertingkat from "./SoalBei/index"
import SoalMengurtkan from "./SoalRankingQuestion/index"
import Inbasket from "./SoalInBasket/index"
import SoalJenisKompetensi from "./SoalJenisKompetansi/index"
import SoalRating from "./SoalRating/soal_rating"
import { useParams, useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import { data } from "autoprefixer"
import jwtDecode from "common/TokenJwt/jwt-decode"
import CryptoJS from "crypto-js"
import Swal from "sweetalert2"
import "../../assets/scss/custom/components/_loading.scss"
import CaptureRecords from '../../components/CaptureRecord/index'
import VerticalLayout from 'components/VerticalLayout';
import HorizontalLayout from 'components/HorizontalLayout';
import { layoutTypes } from 'constants/layout';
import { useSelector } from "react-redux";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};


const IndexPreview = (props) => {

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const Layout = getLayout(layoutType);

  const [data, setData] = useState()
  const [soalPetunjuk, setSoalPetunjuk] = useState()
  const [soal, setSoal] = useState()
  const [idLayout, setIdLayout] = useState()
  const [nameLayout, setNameLayout] = useState()
  const [skenario, setSkenario] = useState()
  const [jenisSoal, setJenisSoal] = useState()
  const [listSosmed, setListSosmed] = useState()
  const [waktu, setWaktu] = useState()
  const [customLayout, setCustomLayout] = useState()
  const [subTest, setSubTest] = useState()

  // const { id } = useParams()
  // const decodeToken = jwtDecode(localStorage.getItem("access_token"))
  // const id_jadwal = decodeToken.id_jadwal
  // const nip = decodeToken.nip

  // const secretKey = "sonsonz"
  // const decryptedBytes = CryptoJS.AES.decrypt(id, secretKey)
  // const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8)
  // const decryptedData = JSON.parse(decryptedText)

  useEffect(() => {
    const customSwal = Swal.mixin({
      customClass: {
        container: "custom-swal-popup",
      },
      html: `
      <div class="ring">
      Loading
      <div class='circle'></div>
      `,
      showConfirmButton: false,
      background: "transparent", // Make the modal background transparent
      customContainerClass: "custom-modal-container", // Apply a custom class to the modal container
    })
    customSwal.fire()
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "soal-preview?id_soal=" +
      props.id_soal,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          console.log(responseData)
          const dataSoal = responseData.data[0].data
          setData(responseData.data[0].data)
          setIdLayout(responseData.data[0].layout.mlb_id)
          setWaktu(responseData.data[0].subtest[0].waktu)
          setNameLayout(responseData.data[0].layout.mlb_name)
          setSkenario(responseData.data[0].skenario)
          setJenisSoal(responseData.data[0].layout.jenis_soal)
          setListSosmed(responseData.data[0].list_sosmed)
          setSoalPetunjuk(dataSoal.filter(item => item.is_petunjuk === "true"))
          setSoal(dataSoal.filter(item => item.is_petunjuk === "false" || item.is_petunjuk === null))
          setCustomLayout(responseData.data[0].custom_layout)
          setSubTest(responseData.data[0].subtest[0])
          customSwal.close()
        } else {
          customSwal.fire({
            text: 'Terjadi kesalahan saat mengambil data !!!',
            icon: 'error',
            showConfirmButton: false,
            timer: 10000, // Close after 10 seconds
          });

          setTimeout(() => {
            window.close(); // Close the window
          }, 10000); // Time in milliseconds before closing
        }
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        customSwal.fire({
          text: 'Terjadi kesalahan saat mengambil data !!!',
          icon: 'error',
          showConfirmButton: false,
          timer: 10000, // Close after 10 seconds
        });

        setTimeout(() => {
          window.close(); // Close the window
        }, 10000); // Time in milliseconds before closing
      });
  }, [])

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     CaptureRecords()
  //     console.log('trst');
  //   }, 30000); // 30.000 milidetik = 30 detik
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, []);

  console.log(soal)
  return (
    <Layout>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {jenisSoal ? (
              <SoalJenisKompetensi
                idLayout={idLayout}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                skenario={skenario}
                idAlattest={1}
                idSubtest={2}
                jenisSoal={jenisSoal}
                listSosmed={listSosmed}
                waktu={waktu}
                customLayout={customLayout}
                subTest={subTest}
              />
            ) : idLayout === 1 || idLayout === 3 || idLayout === 4 ? (
              <SoalMultipleChoice
                idAlattest={1}
                idSubtest={2}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
                subTest={subTest}
              />
            ) : idLayout === 7 ? (
              <SoalText
                idAlattest={1}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                idSubtest={2}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 6 || idLayout === 14 ? (
              <SoalMatrix
                idLayout={idLayout}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                skenario={skenario}
                idAlattest={1}
                idSubtest={2}
                waktu={waktu}
                customLayout={customLayout}
                subTest={subTest}
              />
            ) : idLayout === 9 || idLayout === 10 ? (
              <SoalMengurtkan
                idAlattest={1}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                idSubtest={2}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 13 ? (
              <SoalBertingkat
                idAlattest={1}
                idSubtest={2}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 5 ? (
              <SoalBipolar
                idAlattest={1}
                idSubtest={2}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 2 ? (
              <SoalRating
                idAlattest={1}
                idSubtest={2}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : (
              ""
            )}
          </Container>
        </div>
      </React.Fragment>
    </Layout>
  )
}

export default IndexPreview
