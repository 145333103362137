import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Pagination,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import Navbar from "components/HorizontalLayout/Navbar"
import imagesoal from "../../../../src/assets/images/jobs.png"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const soal_text = props => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const data = props.data
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const customLayout = props.customLayout
  const [statusTImer, setStatusTimer] = useState(false)

  const navigate = useNavigate()
  const [textareaValues, setTextareaValues] = useState(
    Array(data.length).fill("")
  )
  const [matchedData, setMatchedData] = useState([])

  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const totalPage = Math.ceil(props.data.length / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const handleTextareaChange = (index, event) => {
    const updatedTextareaValues = [...textareaValues]
    updatedTextareaValues[index] = event.target.value.toLowerCase() // Konversi ke huruf kecil
    setTextareaValues(updatedTextareaValues)

    // Collect both "kunci" dan "value" dan hapus duplikat
    const pilihanJawaban = props.data[index].pilihan_jawaban
    const kunciDanValue = pilihanJawaban.map(item => ({
      kunci: item.kunci.toLowerCase(), // Konversi ke huruf kecil
      value: item.value.toLowerCase(), // Konversi ke huruf kecil
      id_soal_jawaban: item.id_soal_jawaban,
      id_msoal: item.id_msoal,
      soal: props.data[index].soal,
      no_soal: props.data[index].no_soal,
      pilihan: updatedTextareaValues[index],
      ju_id_indikator_jawaban: item.id_indikator
    }))
    const uniqueKunciDanValue = [...new Set(kunciDanValue)]

    // Mencari kata yang cocok dalam textareaValues dengan kunci
    const matchedItems = uniqueKunciDanValue.filter(pair => {
      const words = updatedTextareaValues[index].split(/[,\.\s]/)
      return words.some(word => word === pair.kunci)
    })

    if (matchedItems.length > 0) {
      setMatchedData(prevData => {
        const newData = [...prevData]
        newData[index] = matchedItems
        return newData
      })
    } else {
      setMatchedData(prevData => {
        const newData = [...prevData]
        newData[index] = {
          id_msoal: props.data[index].id_msoal,
          soal: props.data[index].soal,
          value: "0",
          id_soal_jawaban: null,
          no_soal: props.data[index].no_soal,
          pilihan: updatedTextareaValues[index],
          ju_id_indikator_jawaban: item.id_indikator
        }
        return newData
      })
    }
  }
  const allMatchedData = matchedData.flatMap(data => data)
  //set Text

  //set value

  const inputDataTemporary = () => {
  }
  const HandlleNextPageAndSaveJawban = value => {
  }
  const saveAllData = () => {
  }

  const handleSaveJawaban = () => {
    saveAllData()
  }

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
    width: "100%",
  };

  const layoutSoal = {
    width: "100%",
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };


  return (
    <div>
      <Row>
        <div className="alert alert-success w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            One question, one answer text
          </p>
        </div>
        {props.waktu && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
              <MemoizedCountdownTimer durationInMinutes={props.waktu} setStatusTimer={setStatusTimer} />
            </p>
          </div>
        )}
      </Row>

      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row>
        <Col xl={10} >
          {data.slice(startIndex, endIndex).map((item, index) => (
            <Card className="border border-primary shadow shadow-lg"
              style={customLayout != null ? customLayoutCardSoal : null}
              key={startIndex + index}
            >
              <CardBody>
                <span className="fw-bold">{`Question ${startIndex + index + 1
                  }`}</span>
                <br />
                <div
                  style={customLayout != null ? customLayoutSoal : layoutSoal}
                  dangerouslySetInnerHTML={{
                    __html: item.soal,
                  }}
                />

                <div className="mt-3">
                  <hr size="5" width="100%" className="bg-light" />
                </div>
                <textarea
                  rows={5}
                  className="form-control border border-1 border-primary"
                // value={textareaValues[startIndex + index]}
                // onChange={() =>
                //   handleTextareaChange(startIndex + index, event)
                // }
                />
              </CardBody>
            </Card>
          ))}
          {/* <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => HandlleNextPageAndSaveJawban(page - 1)}
                  />
                </PaginationItem>

                {Array.from({ length: totalPage }).map((_, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink
                      onClick={() => HandlleNextPageAndSaveJawban(i + 1)}
                      href="#"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => HandlleNextPageAndSaveJawban(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row> */}
        </Col>
        <Col xl={2}>
          <HistorySoal
            data={data}
            selectedOptions={allMatchedData}
            setSoal={setPage}
          />
        </Col>
      </Row>
      {/* {isLastPage && (
        <Row>
          <Col xl={12} style={{ marginTop: "10vh" }}>
            <Card className="shadow shadow-lg">
              <CardBody className="text-end">
                <button
                  className="btn-primary btn rounded rounded-3"
                  onClick={() => handleSaveJawaban()}
                >
                  Simpan Jawaban
                </button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )} */}
    </div>
  )
}

export default soal_text
