import React from 'react';
import '../../assets/scss/custom/components/_loading.scss'
function Loading() {
  return (
    <div className='blur-background'>
    <div className="ring">
    Loading
    <div className='circle'></div>
  </div>
    </div>
  );
}

export default Loading;
