import React from "react"
import { Container, Row, Col, CardBody, Card, Button } from "reactstrap"
import imagesoal from "../../../../src/assets/images/jobs.png"
const soal_gambar = () => {
  ;<style>.but</style>
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <div className="alert alert-success w-100">
              <div className="d-flex align-items-center">
                <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
                  <i
                    className="mdi mdi-alert-circle"
                    style={{ lineHeight: "1" }}
                  ></i>{" "}
                  Qutest displays a stimulusthat is symblic so that it does not
                  involve numerical or verbal knowladge.
                </p>
              </div>
            </div>
          </Row>

          <div className="mt-3">
            <hr size="5" width="100%" className="bg-light" />
          </div>
          <Row>
            <Col xl={12}>
              <Card className="bg-transparent border border-secondary">
                <CardBody>
                  <p className="card-title">
                    1. Find a row of pictures consisting of :<br></br>a. Circle{" "}
                    <br></br> b. Point <br></br>c. Line
                  </p>
                  <Card className="border border-secondary">
                    <CardBody className="text-center mx-5">
                      <p className="card-title"></p>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on1"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on1"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on2"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on2"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on3"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on3"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on4"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on4"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on5"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on5"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on6"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on6"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on7"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on7"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on8"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on8"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on9"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on9"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="on10"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="on10"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <br></br>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="1"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="1"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="2"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="2"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="3"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="3"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="4"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="4"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="5"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="5"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="6"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="6"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="7"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="7"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="8"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="8"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="9"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="9"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio"
                        id="10"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="10"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
              <Card className="bg-transparent border border-secondary">
                <CardBody>
                  <p className="card-title">
                    2. Find a row of pictures consisting of :{" "}
                    <p>
                      a. Circle <br></br>b.Point<br></br> c. Line
                    </p>
                  </p>
                  <Card className="border border-secondary">
                    <CardBody className="text-center ">
                      <p className="card-title "></p>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn1"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn1"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn2"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn2"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn3"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn3"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn4"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn4"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn5"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn5"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn6"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn6"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn7"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn7"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn8"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn8"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn9"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn9"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="btn10"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="btn10"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <br></br>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad1"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad1"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad2"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad2"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad3"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad3"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad4"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad4"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad5"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad5"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad6"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad6"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad7"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad7"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad8"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad8"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad9"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad9"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="btnradio2"
                        id="rad10"
                      ></input>
                      <label
                        className="btn btn-outline-primary m-1"
                        htmlFor="rad10"
                      >
                        <i className="mdi mdi-power-settings"></i>
                      </label>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default soal_gambar
