import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Input,
  CardHeader,
} from "reactstrap"
import SoalEmail from "./soal_email"
import SoalKalender from "./soal_kalender"
import DetailSoal from "./detailSoal"
import { useParams, useNavigate } from "react-router-dom"
import _ from "lodash"
import SoalMultipleChoice from "../SoalMultipleChoice/index"
import SoalBipolar from "../SoalBipolar/index"
import SoalMatrix from "../SoalMatrixQuestion/index"
import SoalText from "../SoalText/soal_text"
import SoalBertingkat from "../SoalBei/index"
import SoalMengurtkan from "../SoalRankingQuestion/index"
import { setIndexCase } from "store/actions"

const soal_inbasketemail = props => {
  const [indexSoal, setIndexSoal] = useState(1)
  const [activeIndex, setActiveIndex] = useState()
  const [urutanCase, setUrutanCase] = useState(0)
  const [caseId, setCaseId] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { detail } = useParams()
  const navigate = useNavigate()


  const componenSoal = id_soal => {
    setIndexSoal(id_soal)
    navigate("/SoalInbasket")
  }

  //marge data dengan case id yang sama
  const uniqueCaseIds = [...new Set(props.data.map(item => item.case_id))]

  const filteredData = uniqueCaseIds.map(caseId => {
    return props.data.find(item => item.case_id === caseId)
  })

  //sort by case id
  const sortedData = _.sortBy(filteredData, ["case.case_id"])

  useEffect(() => {
    const idCase = sortedData[urutanCase].case_id
    setCaseId(idCase)
  }, [sortedData, urutanCase])

  const jumlahCase = sortedData.length

  const checkListSoal = (caseId) => {
    const foundItem = props.data.find((item) => item.case_id === caseId);
    if (foundItem) {
      const index = uniqueCaseIds.indexOf(caseId);
      setUrutanCase(index)
    } else {
      console.log(`Case ID: ${caseId} not found.`);
    }
  };


  return (
    <Container fluid>
      <Row>
        <div className="alert alert-primary w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            Soal bentuk {props.jenisSoal}
          </p>
        </div>
      </Row>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row>
        <Col xl={5}>
          <Card className="bg-transparent border border-primary shadow shadow-lg">
            <CardHeader className="fs-5 fw-semibold bg-light">
              {sortedData[urutanCase].case.case_name}
            </CardHeader>
            <CardBody>
              <CardBody>
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{
                    __html: sortedData[urutanCase].case.case,
                  }}
                ></div>
              </CardBody>
            </CardBody>
          </Card>
        </Col>
        <Col xl={5}>
          <Card className="bg-transparent border border-primary shadow shadow-lg">
            <CardHeader className="bg-light fs-5 fw-semibold ">Soal</CardHeader>
            <CardBody>
              {props.idLayout === 1 ||
                props.idLayout === 2 ||
                props.idLayout === 3 ||
                props.idLayout === 4 ? (
                <SoalMultipleChoice
                  idAlattest={props.idAlattest}
                  idSubtest={props.idSubtest}
                  data={props.data}
                  jenisSoal={props.jenisSoal}
                  caseId={caseId}
                  setUrutanCase={setUrutanCase}
                  urutanCase={urutanCase}
                  jumlahCase={jumlahCase}
                  setOptions={setSelectedOptions}
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>

          {/* {indexSoal === 1 ? (
            <Card className="d-flex flex-column bg-transparent border border-primary shadow shadow-lg">
              <CardHeader className="bg-light">
                <div className="card-title">
                  <h4>Soal</h4>
                </div>
              </CardHeader>
              <CardBody>
                <table className="table-responsive table">
                  <thead>
                    <tr>
                      <th>Most Effective</th>
                      <th>Least Effective</th>
                      <th>Your Response</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check form-radio-primary ms-5">
                          <input className="form-check-input" type="radio" />
                        </div>
                      </td>
                      <td>
                        <div className="form-check form-radio-primary ms-5">
                          <input className="form-check-input" type="radio" />
                        </div>
                      </td>
                      <td>
                        <p>
                          Decide that for every visit you do from now on you
                          won’t the leave the shop until the customer...
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check form-radio-primary ms-5">
                          <input className="form-check-input" type="radio" />
                        </div>
                      </td>
                      <td>
                        <div className="form-check form-radio-primary ms-5">
                          <input className="form-check-input" type="radio" />
                        </div>
                      </td>
                      <td>
                        <p>
                          Decide that for every visit you do from now on you
                          won’t the leave the shop until the customer...
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check form-radio-primary ms-5">
                          <input className="form-check-input" type="radio" />
                        </div>
                      </td>
                      <td>
                        <div className="form-check form-radio-primary ms-5">
                          <input className="form-check-input" type="radio" />
                        </div>
                      </td>
                      <td>
                        <p>
                          Decide that for every visit you do from now on you
                          won’t the leave the shop until the customer...
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="text-end m-3">
                  <button className="btn btn-primary send" type="submit">
                    Send Reply
                  </button>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card className="d-flex flex-column bg-transparent border border-secondary">
              <CardHeader className="bg-light">
                <div className="card-title">Soal</div>
              </CardHeader>
              <CardBody>
                <Card className="bg-light border border-transparant">
                  <CardBody>
                    <p className="card-title">
                      <h5 className="text1 mb-3">sample soal</h5>
                    </p>
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="currency"
                            className="card-radio-input"
                            defaultChecked
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>A</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="currency"
                            id="buycurrencyoption2"
                            className="card-radio-input"
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>B</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="currency"
                            id="buycurrencyoption3"
                            className="card-radio-input"
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>C</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="bg-light border border-transparant">
                  <CardBody>
                    <p className="card-title">
                      <h5 className="text1 mb-3">sample soal</h5>
                    </p>
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="soal2"
                            className="card-radio-input"
                            defaultChecked
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>A</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="soal2"
                            id="buycurrencyoption2"
                            className="card-radio-input"
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>B</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="soal2"
                            id="buycurrencyoption3"
                            className="card-radio-input"
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>C</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <Card className="bg-light border border-transparant">
                  <CardBody>
                    <p className="card-title">
                      <h5 className="text1 mb-3">sample soal</h5>
                    </p>
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="soal3"
                            className="card-radio-input"
                            defaultChecked
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>A</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="soal3"
                            className="card-radio-input"
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>B</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                      <div className="d-flex align-items-center">
                        <label className="card-radio-label mb-2">
                          <input
                            type="radio"
                            name="soal3"
                            className="card-radio-input"
                            readOnly
                          />

                          <div className="card-radio card-radio-primary rounded-circle me-3">
                            <div>
                              <span>C</span>
                            </div>
                          </div>
                        </label>
                        <p className="fs-5">Sample Jawaban</p>
                      </div>
                    </div>
                    <div className="text-end m-3">
                      <button className="btn btn-primary send" type="submit">
                        Send Reply
                      </button>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          )} */}
        </Col>
        {props.jenisSoal === "studi kasus" ? (
          <Col xl={2}>
            <Card
              className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-26 h-100"
              style={{ height: "30rem" }}
            >
              <CardHeader className="fs-5 fw-semibold bg-light">
                Nomer Soal
              </CardHeader>
              <CardBody style={{ flex: 1, overflow: "auto" }}>
                <Row>
                  {props.data.map((item, index) => (
                    <Col xl={2} key={index}>
                      <label className="card-radio-label">
                        <input
                          type="radio"
                          onClick={() => checkListSoal(item.case_id)}
                          className="card-radio-input"
                          checked={
                            selectedOptions.findIndex(option => option.id_msoal === item.id_msoal) === index
                          }

                        />

                        <div className="card-radio">{index + 1}</div>
                      </label>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        ) : (
          ""
        )}
      </Row>
    </Container>
  )
}

export default soal_inbasketemail
