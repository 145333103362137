import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CountdownTimer = ({ durationInMinutes, setStatusTimer, statusTimer }) => {
    const [isTenMinuteWarningShown, setIsTenMinuteWarningShown] = useState(false);
    const [remainingTime, setRemainingTime] = useState(durationInMinutes * 60 * 1000);

    // Convert minutes to milliseconds
    const customTime = durationInMinutes * 60 * 1000;

    // Use useRef to store the end time so it doesn't change on re-render
    const endTime = useRef(Date.now() + customTime).current;

    useEffect(() => {
        if (remainingTime <= 10 * 60 * 1000 && !isTenMinuteWarningShown) {
            toast.error('Only 10 minutes remaining!', {
                position: toast.POSITION.TOP_CENTER,
                className: 'danger-toast',
            });
            setIsTenMinuteWarningShown(true);
        }
    }, [remainingTime, isTenMinuteWarningShown]);

    // Your custom callback function when the time is up
    const handleTimeout = () => {
        console.log('Time is up! Your custom function can be called here.');
        setStatusTimer(true);
        // Add your logic here
    };

    // Renderer function for Countdown component
    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render any component when countdown is completed
            return <span>Time is up!</span>;
        } else {
            // Update the remaining time
            const timeLeft = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000) + (seconds * 1000);
            setRemainingTime(timeLeft);

            // Render the countdown
            return (
                <div className="text-center mt-2">
                    <span style={{ lineHeight: "1", fontSize: "20px" }}>
                        <i className="mdi mdi-timer-outline"></i>
                        <span style={{ fontSize: "20px" }}>{hours}</span>:
                        <span style={{ fontSize: "20px" }}>{minutes}</span>:
                        <span style={{ fontSize: "20px" }}>{seconds}</span>
                    </span>
                </div>
            );
        }
    };

    return (
        <div className='m-0 p-0'>
            <Countdown className='mt-2' date={endTime} renderer={renderer} onComplete={handleTimeout} />
            <ToastContainer />
        </div>
    );
};

CountdownTimer.propTypes = {
    durationInMinutes: PropTypes.number.isRequired,
    setStatusTimer: PropTypes.func.isRequired,
    statusTimer: PropTypes.bool.isRequired,
};

export default CountdownTimer;
