import React, { useEffect, useState } from "react"

import {
  Container,
  Row,
  Col,
  Table,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Form,
  FormGroup,
  Label,
  CardBody,
  CardTitle,
  CardSubtitle,
  Collapse,
} from "reactstrap"
import Select from "react-select"

import classnames from "classnames"

import Navbar from "components/HorizontalLayout/Navbar"
import { useNavigate, Link } from "react-router-dom"
import jwtDecode from "common/TokenJwt/jwt-decode"
import Loading from "components/Loading/loading"
import encryptData from "common/Encrypt/encryptData"

const soal_listalattes = () => {
  const [data, setData] = useState()
  const [alatTest, setAlatTest] = useState()
  const [subTest, setSubTest] = useState()
  const [statusSoal, setStatusSoal] = useState()

  const [load, setLoad] = useState()

  const URL_DIGIMAC = "https://dev.alfahuma.tech/digimac_bpkp_2023/"
  const navigate = useNavigate()

  navigate(
    `/dashboard`
  )
  const decodeToken = jwtDecode(localStorage.getItem("access_token"))
  const id_jadwal = decodeToken.id_jadwal

  const [activeTab, setactiveTab] = useState("1")
  const [openAccordions, setOpenAccordions] = useState([])

  const toggleAccordion = index => {
    const updatedOpenAccordions = [...openAccordions]
    updatedOpenAccordions[index] = !updatedOpenAccordions[index]
    setOpenAccordions(updatedOpenAccordions)
  }

  useEffect(() => {
    var myHeaders = new Headers()
    setLoad(true)
    setOpenAccordions([true])
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "AlatTest?" +
      "id_jadwal=" +
      id_jadwal +
      "&nip=" +
      decodeToken.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        const status = responseData.data[0].status
        if (status) {
          setStatusSoal(status)
          setLoad(false)
          setData(responseData.data[0])
        } else {
          setData(responseData.data[0])
          setAlatTest(responseData.data[0].Data.id_mjadwal_alattest)
          setSubTest(responseData.data[0].Data.mjst_id_subtest)
          setLoad(false)
        }
      })
      .catch(error => console.log("error", error))
  }, [])

  const param = {
    idSubtest: subTest,
    idAlattest: alatTest,
  }
  const secretKey = "sonsonz"
  const encryptedToken = encryptData(param, secretKey)

  return (
    <React.Fragment>
      <div className="page-content">
        {load ? (
          <Loading />
        ) : (
          <Container>
            <Row>
              <div className="alert alert-primary w-100 d-flex align-items-center">
                <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
                  <i
                    className="mdi mdi-alert-circle"
                    style={{ lineHeight: "1" }}
                  ></i>{" "}
                  List Alat Test
                </p>
              </div>
            </Row>

            <div className="mt-3">
              <hr size="5" width="100%" className="bg-light" />
            </div>
            {data && (
              <div className="checkout-tabs">
                <Row>
                  <Col lg="2" sm="3">
                    <Nav className="flex-column" pills>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "1" })}
                          onClick={() => {
                            setactiveTab("1")
                          }}
                        >
                          <i className="far fa-clock d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">Belum Dikerjakan</p>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({ active: activeTab === "2" })}
                          onClick={() => {
                            setactiveTab("2")
                          }}
                        >
                          <i className="bx bx-badge-check d-block check-nav-icon mt-4 mb-2" />
                          <p className="fw-bold mb-4">Selesai</p>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col lg="10" sm="9">
                    <Card>
                      <CardBody>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            {statusSoal ? (
                              <Card>
                                <CardBody className="text-left">
                                  <p className="fs-4 fw-bold text-primary">{statusSoal}</p>
                                </CardBody>
                              </Card>
                            ) : (
                              <Card className="shadow-none border mb-0">
                                <CardBody>
                                  <CardTitle className="mb-4">
                                    <div className="bg-primary bg-soft p-3 rounded">
                                      <h5 className="font-size-14 text-primary mb-0">
                                        {data.Data.mja_alattest}
                                      </h5>
                                    </div>
                                  </CardTitle>
                                  <div className="table-responsive">
                                    <Table className="table align-middle mb-0 table-nowrap">
                                      <thead className="table-light">
                                        <tr className="text-center">
                                          <th scope="col">Nomer Subtest</th>
                                          <th scope="col">Nama Subtest</th>
                                          <th scope="col">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="text-center">
                                          <td>{data.Data.mjst_urutan_st}</td>
                                          <td>{data.Data.mjst_subtest}</td>
                                          <td className="badge bg-danger rounded rounded-3 mt-2 mb-2">
                                            {data.Data.status_subtest}
                                          </td>
                                        </tr>
                                        <tr className="text-end mt-3">
                                          <td colSpan="3">
                                            <button
                                              className="btn btn-primary w-25"
                                              onClick={() =>
                                                navigate(
                                                  `/Soal/${encodeURIComponent(
                                                    encryptedToken
                                                  )}`
                                                )
                                              }
                                            >
                                              Mulai Kerjakan
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </CardBody>
                              </Card>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            <div className="accordion" id="accordion">
                              {data.DataSelesai.map((item, index) => (
                                <div className="accordion-item" key={index}>
                                  <h2
                                    className="accordion-header"
                                    id="headingOne"
                                  >
                                    <button
                                      className={classnames(
                                        "accordion-button",
                                        "fw-medium",
                                        { collapsed: !openAccordions[index] }
                                      )}
                                      type="button"
                                      onClick={() => toggleAccordion(index)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item.mja_alattest}
                                    </button>
                                  </h2>

                                  <Collapse
                                    isOpen={openAccordions[index]}
                                    className="accordion-collapse"
                                  >
                                    <div className="accordion-body">
                                      <div className="table-responsive">
                                        <Table className="table align-middle mb-0 table-nowrap">
                                          <thead className="table-light">
                                            <tr>
                                              <th scope="col">Nomer Subtest</th>
                                              <th scope="col">Nama Subtest</th>
                                              <th scope="col">Status</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.sub_test.map(
                                              (item2, index2) => (
                                                <tr key={index2}>
                                                  <td>
                                                    {item2.mjst_urutan_st}
                                                  </td>
                                                  <td>{item2.mjst_subtest}</td>
                                                  <td className="badge bg-success rounded rounded-3 mt-2 mb-2">
                                                    {item2.status_subtest}
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </Table>
                                      </div>
                                    </div>
                                  </Collapse>
                                </div>
                              ))}
                            </div>
                          </TabPane>
                        </TabContent>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

export default soal_listalattes
