import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import Swal from "sweetalert2"
import { json, useNavigate } from "react-router-dom"

const SaveJawaban = ({
  idAlattest,
  idSubtest,
  id_jadwal,
  nip,
  id_asesi,
  setKondisiSoal,
  kondisiSoal,
  setPage,
  setSelectedOptions,
  statusTImer,
  dataSoal,
  soalPetunjuk,
  selectedOptions,
  TotalSemuaJawaban,
}) => {
  const navigate = useNavigate()
  const handleSaveJawaban = () => {
    console.log(TotalSemuaJawaban, dataSoal.length)
    if (TotalSemuaJawaban !== dataSoal.length) {
      Swal.fire({
        title: "Incomplete!",
        text: "Please make sure to complete all the questions first.",
        icon: "warning",
        confirmButtonText: "OK",
      })
      return null
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to submit your answers?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, submit it!",
      cancelButtonText: "No, cancel!",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Processing data...",
          text: "Please wait a moment...", // Optional text to provide additional information
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
          showConfirmButton: false,
        })

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token")
        )

        var requestOptions = {
          method: "PUT",
          headers: myHeaders,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_BACKEND_URL_VERSION +
            "jawaban/updateStatus?idSubtest=" +
            idSubtest +
            "&idAlattest=" +
            idAlattest +
            "&nip=" +
            nip +
            "&id_jadwal=" +
            id_jadwal +
            "&id_asesi=" +
            id_asesi,
          requestOptions
        )
          .then(response => response.text())
          .then(result => {
            navigate("/soalListAlatTes")
            Swal.fire({
              title: "Process completed!",
              icon: "success",
              timer: 1000, // Set the timer to 1000 milliseconds (1 second)
              showConfirmButton: false, // Hide the Confirm button
            })
          })
          .catch(error => console.log("error", error))
      }
    })
  }
  const handleMulaiMengerjakan = () => {
    setKondisiSoal(false)
    setPage(1)
    setSelectedOptions([])
  }

  useEffect(() => {
    if (statusTImer === true) {
      Swal.fire({
        title: "Processing data...",
        text: "Please wait a moment...", // Optional text to provide additional information
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
        showConfirmButton: false,
      })

      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION +
          "jawaban/updateStatus?idSubtest=" +
          idSubtest +
          "&idAlattest=" +
          idAlattest +
          "&nip=" +
          nip +
          "&id_jadwal=" +
          id_jadwal +
          "&id_asesi=" +
          id_asesi,
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          navigate("/soalListAlatTes")
          Swal.fire({
            title: "Process completed!",
            icon: "success",
            timer: 1000, // Set the timer to 1000 milliseconds (1 second)
            showConfirmButton: false, // Hide the Confirm button
          })
        })
        .catch(error => console.log("error", error))
    }
    console.log(statusTImer)
  }, [statusTImer])

  console.log(statusTImer)

  return (
    <Col xl={12} style={{ marginTop: "10px" }}>
      {kondisiSoal ? (
        <Card
          className="d-flex justify-content-end "
          style={{ backgroundColor: "transparent" }}
        >
          <CardBody className="text-end">
            <button
              className="btn-primary btn rounded rounded-3"
              onClick={() => handleMulaiMengerjakan()}
            >
              Mulai Mengerjakan
            </button>
          </CardBody>
        </Card>
      ) : (
        <Card
          className="d-flex justify-content-end "
          style={{ backgroundColor: "transparent" }}
        >
          <CardBody className="text-end">
            <Button
              color="primary"
              // disabled={totalJawaban !== dataSoal.length}
              onClick={handleSaveJawaban}
            >
              Save Jawaban
            </Button>
          </CardBody>
        </Card>
      )}
    </Col>
  )
}

export default SaveJawaban
