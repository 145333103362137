import React, { useRef, useState, useEffect } from "react";
import jwtDecode from "common/TokenJwt/jwt-decode";

const Index = () => {
  const proctoring = localStorage.getItem("proctoring_status")
  if (proctoring === "on") {
    const DecodeJwt = jwtDecode(localStorage.getItem("access_token"));

    // Membuat elemen video untuk menampilkan feed dari kamera
    const videoElement = document.createElement("video");
    videoElement.style.display = "none";
    document.body.appendChild(videoElement);

    // Membuat elemen canvas untuk menampilkan gambar yang akan diambil
    const canvasElement = document.createElement("canvas");
    canvasElement.style.display = "none"; // Sembunyikan elemen canvas
    canvasElement.style.height = "0px";
    document.body.appendChild(canvasElement);

    // Mengakses kamera dan menampilkan feed kamera di elemen video
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        videoElement.srcObject = stream;
        videoElement.play();

        videoElement.style.display = "none"; // Hide video element

        // Mengambil gambar setelah feed kamera tersedia
        setTimeout(() => {
          canvasElement.width = videoElement.videoWidth;
          canvasElement.height = videoElement.videoHeight;
          const context = canvasElement.getContext("2d");
          context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

          // Mengambil gambar sebagai objek Blob
          canvasElement.toBlob((blob) => {
            // Cetak objek Blob ke konsol
            console.log("Objek Blob Gambar:", blob);
            var myHeaders = new Headers();
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token"));
            var formdata = new FormData();
            formdata.append("id_jadwal", DecodeJwt.id_jadwal);
            formdata.append("nip", DecodeJwt.nip);
            formdata.append("nama", DecodeJwt.nama);
            formdata.append("id_jadwal_asesi", DecodeJwt.id_jadwal_asesi);
            formdata.append("image", blob);

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            };

            fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "capture", requestOptions)
              .then((response) => response.text())
              .then((result) => {
                const Response = JSON.parse(result);
                if (Response.meta.code === 200) {
                  return console.log("berhasil menyimpan foto");
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          }, "image/jpeg");
        }, 2000); // Tunggu 2 detik sebelum mengambil gambar (sesuaikan dengan kebutuhan)

      })
      .catch((error) => {
        console.error("Gagal mengakses kamera:", error);
      });
  }
};

export default Index;
