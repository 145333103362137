import React, { useState } from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"

const history_soal = ({
  data,
  selectedOptions,
  setSoal,
  tandaiState = [],
  currentIndex,
  setCurrentIndex, // Default tandaiState ke array kosong jika tidak ada
  totalJawaban,
  TotalSemuaJawaban,
  statusPetunjuk
}) => {
  // Tambahkan state untuk melacak indeks soal saat ini

  const checkListSoal = (caseId, uniqueCaseIds) => {
    const foundItem = data.find(item => item.case_id === caseId)
    if (foundItem) {
      const index = uniqueCaseIds.indexOf(caseId)
      setUrutanCase(index) // Harap pastikan setUrutanCase didefinisikan dan sesuai dengan kebutuhan
    } else {
      console.log(`Case ID: ${caseId} not found.`)
    }
  }

  console.log(selectedOptions)
  return (
    <div>
      <Card
        className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column mh-26 h-100"
        style={{ height: "30rem" }}
      >
        <CardHeader className="fs-5 fw-semibold bg-primary text-light">
          Nomor Soal

          {statusPetunjuk === false && (
            <p className="text-end fs-6"> {TotalSemuaJawaban} / {data.length}
            </p>
          )}
        </CardHeader>
        <CardBody style={{ flex: 1, overflow: "auto", margin: "0px", padding: "0px" }}>
          <Row>
            {data.map((item, index) => {
              const isSelected = selectedOptions[index] !== undefined
              return (
                <Col xl={2} key={index}>
                  <label className="card-radio-label">
                    <input
                      type="radio"
                      onClick={() => {
                        setSoal(index + 1)
                        setCurrentIndex(index) // Update currentIndex saat soal dipilih
                      }}
                      className={`card-radio-input`}
                      checked={
                        selectedOptions.some(
                          option => option.id_msoal === item.id_msoal
                        ) &&
                        !tandaiState.includes(index) &&
                        currentIndex !== index
                      }
                    />
                    <div
                      className={`card-radio ${tandaiState.includes(index)
                        ? "bg-danger"
                        : currentIndex === index
                          ? "bg-secondary text-white"
                          : ""
                        }`}
                      onClick={() => {
                        setSoal(index + 1)
                        setCurrentIndex(index) // Update currentIndex saat soal dipilih
                      }}
                    >
                      {index + 1}
                    </div>
                  </label>
                </Col>
              )
            })}
          </Row>
        </CardBody>
      </Card>
    </div>
  )
}

export default history_soal
