import CryptoJS from "crypto-js";
import Base64 from "crypto-js/enc-base64";

const encryptData = (jsonData, secretKey) => {
  const jsonString = JSON.stringify(jsonData);

  // Encrypt the JSON data and convert it to Base64
  const encryptedData = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
  return encryptedData;
};

export default encryptData;
