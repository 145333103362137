import React, { useState, useEffect } from "react";
import { Col, Container, PaginationItem, PaginationLink, Row } from "reactstrap";
import { map } from "lodash";
import Breadcrumbs from "components/Common/Breadcrumb";
import CardProject from "./card-project";
import jwtDecode from "common/TokenJwt/jwt-decode"
import CryptoJS from "crypto-js"
import Loading from "components/Loading/loading";
import VerticalLayout from 'components/VerticalLayout';
import HorizontalLayout from 'components/HorizontalLayout';
import { layoutTypes } from 'constants/layout';
import { useSelector } from "react-redux";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const ProjectsGrid = (props) => {

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const Layout = getLayout(layoutType);

  const [data, setData] = useState();
  const itemsPerPage = 9;
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1)
  const idJadwal = props.idJadwal
  const [slicedGroups, setSlicedGroups] = useState([]);

  const handlePageClick = (page) => {
    setPage(page);
  };

  useEffect(() => {
    if (slicedGroups) {
      const totalPage = Math.ceil(slicedGroups.length / itemsPerPage);
      setTotalPage(totalPage)
    }
  }, [slicedGroups])

  useEffect(() => {
    const fetchData = () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token"));

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "proctoring?id_jadwal=" + idJadwal, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.meta.code === 200) {
            setData(result.data[0]);
          }
        })
        .catch((error) => console.log('error', error));
    };

    // Initial fetch
    fetchData();

    // Set up an interval to fetch data every 120 seconds
    const intervalId = setInterval(fetchData, 1200000);

    // Clean up the interval on unmount to prevent memory leaks
    return () => clearInterval(intervalId);
  }, [idJadwal]);

  useEffect(() => {
    if (data && data.length > 0) {
      // Sort the data by mp_id_ja and updated_at in descending order
      const sortedData = data.sort((a, b) => {
        if (a.mp_id_ja !== b.mp_id_ja) {
          return a.mp_id_ja - b.mp_id_ja;
        }
        return new Date(b.updated_at) - new Date(a.updated_at);
      });

      // Slice the array into groups of up to 4 items with the same mp_id_ja
      const groups = [];
      let currentGroup = [];
      let currentIdJa = null;

      sortedData.forEach(item => {
        if (item.mp_id_ja !== currentIdJa || currentGroup.length === 4) {
          if (currentGroup.length > 0) {
            groups.push(currentGroup);
            currentGroup = [];
          }
          currentIdJa = item.mp_id_ja;
        }
        currentGroup.push(item);
      });

      // Push the last group
      if (currentGroup.length > 0) {
        groups.push(currentGroup);
      }

      // Update state with sliced groups
      setSlicedGroups(groups);
    }
  }, [data]);

  console.log(slicedGroups)


  return (
    <Layout>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Projects" breadcrumbItem="Proctoring" />
          <Row>
            {slicedGroups && slicedGroups.length > 0 && (
              <CardProject currentPage={page} dummyProjects={slicedGroups} itemsPerPage={itemsPerPage} />
            )}
          </Row>
          <Row>
            {data && (
              <Col lg="12">
                <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                  <PaginationItem disabled={page === 1} style={{ margin: '0 5px' }}>
                    <PaginationLink
                      className="fs-5" style={{ borderRadius: "4px" }}
                      previous
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page - 1);
                      }}
                    >
                      <span style={{ display: 'inline-block', transform: 'rotate(180deg)' }}>&#10140;</span> Sebelumnya
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem disabled={page === totalPage} style={{ margin: '0 5px' }}>
                    <PaginationLink
                      className="fs-5" style={{ borderRadius: "4px" }}
                      next
                      onClick={(e) => {
                        e.preventDefault();
                        setPage(page + 1);
                      }}>
                      Selanjutnya &#10140;
                    </PaginationLink>

                  </PaginationItem>
                </ul>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default ProjectsGrid;
