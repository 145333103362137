import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationItem,
  PaginationLink,
  CardHeader,
  Collapse,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { toInteger } from "lodash"
import _ from "lodash"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import HistorySoal from "components/HistorySoal/history_soal"
import { data } from "autoprefixer"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
import classnames from "classnames"

const MemoizedCountdownTimer = React.memo(CountdownTimer)

const tipeA = ({
  data,
  idAlattest,
  idSubtest,
  jenisSoal,
  caseId,
  urutanCase,
  setUrutanCase,
  jumlahCase,
  setOptions,
  dataSoal,
  soalPetunjuk,
  waktu,
  customLayout,
  statusTimer,
  subTest,
  showInstruksi,
  showTimer,
  isExpand,
  onExpandToggle,
  onKondisiSoalChange,
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionsPetunjuk, setSelectedOptionsPetunjuk] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState(true)
  const [page, setPage] = useState(1)
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)
  const [tandaiState, setTandaiState] = useState([])
  const [totalJawaban, setTotalJawaban] = useState(0)
  const [answeredCounts, setAnsweredCounts] = useState({})
  const [totalAnswered, setTotalAnswered] = useState(0)
  const [totalJawabanByQuestion, setTotalJawabanByQuestion] = useState({})
  const [TotalSemuaJawaban, setTotalSemuaJawaban] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(null)

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    ju_id_indikator_jawaban,
    ju_id_indikator
  ) => {
    const updatedSelectedOptions = [...selectedOptions]
    const index = parseInt(no_soal) - 1

    updatedSelectedOptions[index] = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      ju_id_indikator_jawaban,
      ju_id_indikator,
    }

    setSelectedOptions(updatedSelectedOptions)

    // Update total jawaban
    const updatedAnsweredCounts = { ...answeredCounts }
    updatedAnsweredCounts[no_soal] = (updatedAnsweredCounts[no_soal] || 0) + 1

    const totalAnswered = Object.keys(updatedAnsweredCounts).length
    setAnsweredCounts(updatedAnsweredCounts)
    setTotalAnswered(totalAnswered)
    setTotalJawaban(Object.keys(updatedSelectedOptions).length)
  }

  const handleRadioChangePetunjuk = (id_msoal, id_soal_jawaban, no_soal) => {
    const updatedSelectedOptions = [...selectedOptionsPetunjuk]
    const index = parseInt(no_soal) - 1
    for (let i = 0; i < soalPetunjuk.length; i++) {
      if (i === index) {
        // Jika data sudah ada pada indeks yang sesuai, gabungkan data baru
        if (updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = {
            ...updatedSelectedOptions[i],
            id_msoal,
            id_soal_jawaban,
          }
        } else {
          // Jika data belum ada pada indeks yang sesuai, isi data baru
          updatedSelectedOptions[i] = {
            id_msoal,
            id_soal_jawaban,
          }
        }
      } else {
        // Jika indeks tidak sesuai, tambahkan array kosong
        if (!updatedSelectedOptions[i]) {
          updatedSelectedOptions[i] = []
        }
      }
    }

    setSelectedOptionsPetunjuk(updatedSelectedOptions)
  }

  const getDataJawaban = () => {
    // Swal.fire({
    //   title: "Proses data...",
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading()
    //   },
    //   showConfirmButton: false,
    // })
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
        "jawaban/viewJawaban?" +
        "id_subtest=" +
        idSubtest +
        "&id_alattest=" +
        idAlattest +
        "&id_jadwal=" +
        DecodeJwt.id_jadwal +
        "&nip=" +
        DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          // setkondisiSoal(false)
          setSelectedOptions(responseData.data[0])
          setOptions(requestOptions.data[0])
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        } else {
          // setkondisiSoal(true)
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        }
      })
      .catch(error => {
        setkondisiSoal(true)
        // Swal.fire({
        //   title: "Proses selesai!",
        //   icon: "success",
        //   timer: 1000,
        //   showConfirmButton: false,
        // })
      })
  }

  useEffect(() => {
    if (selectedOptions.length > 0) {
      // Swal.fire({
      //   title: "Proses data...",
      //   allowOutsideClick: false,
      //   onBeforeOpen: () => {
      //     Swal.showLoading()
      //   },
      //   timer: 100,
      //   showConfirmButton: false,
      // })

      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token")
      )
      var formdata = new FormData()
      formdata.append("id_jadwal", DecodeJwt.id_jadwal)
      formdata.append("nip", DecodeJwt.nip)
      formdata.append("id_alattest", idAlattest)
      formdata.append("id_subtest", idSubtest)
      formdata.append("data", JSON.stringify(selectedOptions))

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
        requestOptions
      )
        .then(response => response.text())
        .then(result => {
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        })
        .catch(error => {
          console.log("error", error)
          Swal.fire({
            title: "Error",
            text: "Terjadi kesalahan saat proses simpan jawaban",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/dashboard")
          })
        })
    } else {
      getDataJawaban()
    }
  }, [selectedOptions])

  const handleChecked = id_soal_jawaban => {
    for (let index = 0; index < dataSoal.length; index++) {
      if (
        selectedOptions[index] &&
        selectedOptions[index].id_soal_jawaban === id_soal_jawaban
      ) {
        return true
      }
    }
  }
  const handleCheckedPetunjuk = id_soal_jawaban => {
    for (let index = 0; index < soalPetunjuk.length; index++) {
      if (
        selectedOptionsPetunjuk[index] &&
        selectedOptionsPetunjuk[index].id_soal_jawaban === id_soal_jawaban
      ) {
        return true
      }
    }
  }

  const handleTandaiSoal = () => {
    setTandaiState(prevState => {
      const updatedState = [...prevState]
      if (!updatedState.includes(page - 1)) {
        updatedState.push(page - 1)
        Swal.fire({
          title: "Soal Nomor " + page + " Ditandai",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        })
      } else {
        updatedState.splice(updatedState.indexOf(page - 1), 1)
      }
      return updatedState
    })
  }

  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
    }
  }, [kondisiSoal])

  useEffect(() => {
    if (onKondisiSoalChange) {
      onKondisiSoalChange(kondisiSoal)
    }
  }, [kondisiSoal, onKondisiSoalChange])

  useEffect(() => {
    const counts = {}

    selectedOptions.forEach(option => {
      const { no_soal = "unknown" } = option
      if (no_soal !== null && no_soal !== undefined) {
        if (!counts[no_soal]) {
          counts[no_soal] = 0
        }
        counts[no_soal] += 1
      }
    })

    const total = Object.keys(counts).length
    console.log("total: ", total)
    setAnsweredCounts(counts)
    setTotalSemuaJawaban(total)

    Object.entries(counts).map(([questionNo, count]) => ({
      Nomor: questionNo,
      TotalJawaban: count,
      TotalSemuaJawaban: total,
    }))
  }, [selectedOptions])

  console.log("soal=-petunjuk : " + soalPetunjuk.length)
  //pagination
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const totalPage = Math.ceil(dataPage / itemsPerPage)
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  const [openAccordions, setOpenAccordions] = useState(isExpand)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  const [soalState, setSoalState] = useState(9)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const expand = () => {
    setSoalState(soalState === 9 ? 12 : 9)
    setPetunjukStatus(!petunjukStatus)
    onExpandToggle() // Notify parent to update state
  }
  useEffect(() => {
    setPage(currentIndex + 1)
    // console.log("INDEX HALAMAN SAAT INI: ", currentIndex)
  }, [currentIndex])

  const nextPage = page => {
    setPage(page + 1)
    setCurrentIndex(page)
  }

  const prevPage = page => {
    setPage(page - 1)
    setCurrentIndex(page - 2)
  }

  const renderCountdownTimer = () => {
    if (!kondisiSoal) {
      return <MemoizedCountdownTimer waktu={waktu} />
    }
    return null
  }

  return (
    <div>
      <Row>
        {!showInstruksi && (
          <div className="accordion m-3" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames(
                    "accordion-button",
                    "fw-medium",
                    "fs-1",
                    {
                      collapsed: !openAccordions,
                    }
                  )}
                  type="button"
                  onClick={() => toggleAccordion()}
                  style={{ cursor: "pointer" }}
                >
                  Instruksi
                </button>
              </h2>

              <Collapse isOpen={openAccordions} className="accordion-collapse">
                <div className="accordion-body">
                  <CardBody className="bg-light rounded rounded-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subTest?.instruksi,
                      }}
                    />
                  </CardBody>
                </div>
              </Collapse>
            </div>
          </div>
        )}
        {waktu && kondisiSoal === false && !showTimer && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>
      <div className="m-0 p-0">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Card className="bg-transparent border border-primary shadow shadow-lg d-flex flex-column  full-height">
        <CardBody
          style={{ flex: 1, overflow: "auto", margin: "0px", padding: "0px" }}
        >
          <Row>
            <Col xl={soalState}>
              {soalPetunjuk.length > 0 && kondisiSoal
                ? soalPetunjuk
                    .slice(startIndex, endIndex)
                    .map((item, index) => (
                      <Card
                        className=" border border-primary d-flex flex-column"
                        key={index}
                        style={
                          customLayout != null ? customLayoutCardSoal : null
                        }
                      >
                        <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                          <label className="mt-1">
                            {" "}
                            Soal Nomor {item.no_soal}
                          </label>
                          <button
                            type="button"
                            onClick={() => {
                              expand()
                            }}
                            className="btn noti-icon text-white float-end"
                          >
                            <i
                              className="bx bx-fullscreen"
                              style={{ color: "white" }}
                            />
                          </button>
                        </CardHeader>
                        <CardBody>
                          <p className="card-text">
                            <div
                              style={
                                customLayout != null ? customLayoutSoal : null
                              }
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            />
                          </p>
                          <Row className="d-flex justify-content-center">
                            {item.pilihan_jawaban.map((item2, index2) => (
                              <Col xl="12" sm="12" key={index2}>
                                <label
                                  className="card-radio-label"
                                  style={
                                    customLayout != null
                                      ? customLayoutCardJawaban
                                      : null
                                  }
                                >
                                  <input
                                    type="radio"
                                    name={`pilihan_${startIndex + index}`}
                                    onClick={() =>
                                      handleRadioChangePetunjuk(
                                        item.id_msoal,
                                        item2.id_soal_jawaban,
                                        item.no_soal
                                      )
                                    }
                                    className="card-radio-input"
                                    checked={handleCheckedPetunjuk(
                                      item2.id_soal_jawaban
                                    )}
                                  />

                                  <div className="card-radio border border-primary border-1 d-flex">
                                    <div
                                      style={
                                        customLayout != null
                                          ? customLayoutJawaban
                                          : {
                                              width: "100%",
                                              wordWrap: "break-word",
                                              whiteSpace: "normal",
                                            }
                                      }
                                      className="mt-2"
                                      dangerouslySetInnerHTML={{
                                        __html: item2.pilihan,
                                      }}
                                    ></div>
                                  </div>
                                </label>
                              </Col>
                            ))}
                          </Row>
                        </CardBody>
                      </Card>
                    ))
                : dataSoal.slice(startIndex, endIndex).map((item, index) => (
                    <Card
                      className="border border-primary d-flex flex-column"
                      key={index}
                      style={
                        customLayout != null
                          ? customLayoutCardSoal
                          : { flex: 1 }
                      }
                    >
                      <CardHeader className="fs-5 fw-semibold bg-primary text-light">
                        <label className="mt-1">
                          {" "}
                          Soal Nomor {item.no_soal}
                        </label>

                        <button
                          type="button"
                          onClick={() => {
                            expand()
                          }}
                          className="btn noti-icon text-white float-end"
                        >
                          <i
                            className="bx bx-fullscreen"
                            style={{ color: "white" }}
                          />
                        </button>
                        <Button
                          className="float-end btn btn-danger"
                          color={
                            tandaiState.includes(page - 1)
                              ? "danger"
                              : "primary"
                          }
                          onClick={handleTandaiSoal}
                        >
                          {tandaiState.includes(page - 1)
                            ? "Hapus Penandaan"
                            : "Tandai"}
                        </Button>
                      </CardHeader>
                      <CardBody style={{ flex: 1 }}>
                        <p className="card-text">
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          />
                        </p>
                        <Row className="d-flex justify-content-center">
                          {item.pilihan_jawaban.map((item2, index2) => (
                            <Col xl="12" sm="12" key={index2}>
                              <label
                                className="card-radio-label"
                                style={
                                  customLayout != null
                                    ? customLayoutCardJawaban
                                    : null
                                }
                              >
                                <input
                                  type="radio"
                                  name={`pilihan_${startIndex + index}`}
                                  onClick={() =>
                                    handleRadioChange(
                                      item.id_msoal,
                                      item.mt_idsubtest,
                                      item.no_soal,
                                      item.soal,
                                      item2.id_soal_jawaban,
                                      item2.pilihan,
                                      item2.value,
                                      item2.id_indikator,
                                      item.id_indikator
                                    )
                                  }
                                  className="card-radio-input"
                                  checked={handleChecked(item2.id_soal_jawaban)}
                                />

                                <div className="card-radio border border-primary border-1 d-flex">
                                  <div
                                    style={
                                      customLayout != null
                                        ? customLayoutJawaban
                                        : {
                                            width: "100%",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                          }
                                    }
                                    className="mt-2"
                                    dangerouslySetInnerHTML={{
                                      __html: item2.pilihan,
                                    }}
                                  ></div>
                                </div>
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
              <Row>
                <Col lg="12">
                  <ul className="pagination justify-content-center mt-2 mb-5">
                    <PaginationItem
                      disabled={page === 1}
                      style={{ margin: "0 5px" }}
                    >
                      <PaginationLink
                        className="fs-5"
                        style={{ borderRadius: "4px" }}
                        previous
                        onClick={e => {
                          e.preventDefault()
                          prevPage(page)
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            transform: "rotate(180deg)",
                          }}
                        >
                          &#10140;
                        </span>{" "}
                        Sebelumnya
                      </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                      disabled={page === totalPage}
                      style={{ margin: "0 5px" }}
                    >
                      <PaginationLink
                        className="fs-5"
                        style={{ borderRadius: "4px" }}
                        next
                        onClick={e => {
                          e.preventDefault()
                          nextPage(page)
                        }}
                      >
                        Selanjutnya &#10140;
                      </PaginationLink>
                    </PaginationItem>
                  </ul>
                </Col>
              </Row>
            </Col>
            {petunjukStatus && (
              <Col xl={3}>
                <HistorySoal
                  data={kondisiSoal ? soalPetunjuk : dataSoal}
                  selectedOptions={
                    kondisiSoal ? selectedOptionsPetunjuk : selectedOptions
                  }
                  setSoal={setPage}
                  pageSoal={page - 1}
                  tandaiState={Array.isArray(tandaiState) ? tandaiState : []}
                  currentIndex={currentIndex}
                  setCurrentIndex={setCurrentIndex}
                  totalJawaban={TotalSemuaJawaban}
                  TotalSemuaJawaban={TotalSemuaJawaban}
                  statusPetunjuk={kondisiSoal ? true : false}
                />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
      {(isLastPage === true || statusTImer === true) && (
        <Row>
          <SaveJawaban
            idAlattest={idAlattest}
            idSubtest={idSubtest}
            id_jadwal={DecodeJwt.id_jadwal}
            nip={DecodeJwt.nip}
            id_asesi={DecodeJwt.id_jadwal_asesi}
            kondisiSoal={kondisiSoal}
            setKondisiSoal={setkondisiSoal}
            setPage={setPage}
            statusTImer={statusTImer}
            dataSoal={dataSoal}
            soalPetunjuk={soalPetunjuk}
            selectedOptions={selectedOptions}
            totalJawaban={totalJawaban}
            TotalSemuaJawaban={TotalSemuaJawaban}
          />
        </Row>
      )}
    </div>
  )
}

export default tipeA
