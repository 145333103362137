import jwtDecode from "common/TokenJwt/jwt-decode"
import React, { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Webcam from "react-webcam"
import { CardBody, CardHeader, Col, Row, Card, Container } from "reactstrap"
import Swal from "sweetalert2"
import Switch from "react-switch";

const index = () => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const proctoring = jwtDecode(localStorage.getItem("proctoring_status"))
  const webcamRef = useRef(null)
  const [capturedImage, setCapturedImage] = useState(null)
  const [fileCapture, setFileCapture] = useState(null)
  const navigate = useNavigate()
  const [switchValue, setSwitchValue] = useState(proctoring === 'off' ? false : true)

  const capture = async () => {
    try {
      const imageSrc = webcamRef.current.getScreenshot()
      setCapturedImage(imageSrc);
      const blob = dataURItoBlob(imageSrc);
      const capturedFile = new File([blob], "captured.jpg", { type: "image/jpeg" });
      setFileCapture(capturedFile);
    } catch (error) {
      console.error("Failed to access the camera:", error)
    }
  }
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }
  const handleSave = () => {
    Swal.fire({
      title: "Proses data...",
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
      showConfirmButton: false,
    })

    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )
    var formdata = new FormData()
    formdata.append("id_jadwal", DecodeJwt.id_jadwal)
    formdata.append("nip", DecodeJwt.nip)
    formdata.append("nama", DecodeJwt.nama)
    formdata.append("id_jadwal_asesi", DecodeJwt.id_jadwal_asesi)
    formdata.append("image", fileCapture)

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION + "capture",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const Response = JSON.parse(result)
        if (Response.meta.code == 200) {
          Swal.fire({
            title: "Proses selesai!",
            icon: "success",
            timer: 1000,
            showConfirmButton: false,
          })
          navigate("/dashboard")
        }
      })
      .catch(error => {
        console.log("error", error)
        // Menutup SweetAlert jika terjadi kesalahan
        Swal.fire({
          title: "Terjadi kesalahan",
          icon: "error",
        })
      })
  }

  const passProctoring = () => {
    navigate("/dashboard")
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container>
          <Row>
            <div className="text-center">
              <div className="mt-5 alert alert-primary w-100">
                <div className="d-flex align-items-center">
                  <p
                    className="fs-5 fw-medium ms-3"
                    style={{ lineHeight: "1" }}
                  >
                    <i
                      className="mdi mdi-alert-circle"
                      style={{ lineHeight: "1" }}
                    ></i>{" "}
                    Allow Access Camera, dan cari cahaya terang untuk mengambil foto
                  </p>
                </div>
              </div>
            </div>
          </Row>
          <Row>
            <Col xl={6} className="mx-auto">
              <Card className="text-center" style={{ height: "auto" }}>
                <CardBody>
                  {capturedImage && switchValue === true ? (
                    <div>
                      <img
                        src={capturedImage}
                        alt="Captured"
                        width="500"
                        height="500"
                      />
                      <button
                        className="btn btn-danger w-25 mt-2"
                        onClick={() => setCapturedImage(null)}
                      >
                        <i className="mdi-refresh mdi"> Reset</i>
                      </button>
                      <button className="btn btn-primary w-25 mt-2 ms-2" onClick={() => handleSave()}>
                        Save
                      </button>
                    </div>
                  ) : switchValue === false ? (
                    <>
                      <div className="d-flex align-items-center text-center m-2">
                        <p
                          className="fs-5 fw-medium ms-3"
                          style={{ lineHeight: "1" }}
                        >
                          <i
                            className="mdi mdi-alert-circle"
                            style={{ lineHeight: "1" }}
                          ></i>{" "}
                          Proctoring telah dimatikan
                        </p>
                      </div>
                      <div>
                        <button className="btn btn-success w-25 mt-2 ms-2" onClick={() => passProctoring()}>
                          Lanjutkan
                        </button>
                      </div>
                    </>
                  ) : (
                    <div>
                      <Webcam
                        audio={false}
                        height={500}
                        width={500}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                      />
                      <button
                        className="btn btn-success w-25 mt-4"
                        onClick={capture}
                      >
                        Capture
                      </button>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default index
