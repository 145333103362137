import { Container, Row, Col, CardBody, Card, Button } from "reactstrap"
import Navbar from "components/HorizontalLayout/Navbar"
import TipeA from "./tipeA"
import React, { useState, useEffect } from "react"
const index = props => {
  const [view, SetView] = useState(false)

  const tampil = () => {
    if (view == true) {
      SetView(false)
    } else {
      SetView(true)
    }
  }

  return (
    <div>
      {/* <button className="btn btn-primary m-3" onClick={tampil}>
        <i className="mdi-eye mdi"></i>
      </button> */}
      {/* {props.idLayout === 14 ? (
        <TipeB
          data={props.data}
          dataSoal={props.dataSoal}
          soalPetunjuk={props.soalPetunjuk}
          skenario={props.skenario}
          idAlattest={props.idAlattest}
          idSubtest={props.idSubtest}
        />
      ) : ( */}
      <TipeA
        data={props.data}
        dataSoal={props.dataSoal}
        soalPetunjuk={props.soalPetunjuk}
        idAlattest={props.idAlattest}
        idSubtest={props.idSubtest}
        waktu={props.waktu}
        customLayout={props.customLayout}
        subTest={props.subTest}
      />
      {/* )} */}
    </div>
  )
}

export default index
