import React, { useState, useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import index from "."
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import HistorySoal from "components/HistorySoal/history_soal"
import { set } from "lodash"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const TipeB = props => {
  const { data } = props
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))

  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const customLayout = props.customLayout

  const navigate = useNavigate()
  // Initialize the state with the initial data
  const [questions, setQuestions] = useState([])
  const [kunciArray, setKunciArray] = useState([])
  const [jawaban, setJawaban] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState()
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  useEffect(() => {
    // Swal.fire({
    //   title: "Proses data...",
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading()
    //   },
    //   showConfirmButton: false,
    // })
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/viewJawabanUrut?" +
      "id_subtest=" +
      idSubtest +
      "&id_alattest=" +
      props.idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          setQuestions(responseData.data[0])
        }
      })
      .catch(error => {
        // Swal.fire({
        //   title: "Terjadi Kesalahan",
        //   icon: "error",
        //   timer: 1000,
        //   showConfirmButton: false,
        // })
      })
  }, [])

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/viewJawaban?" +
      "id_subtest=" +
      idSubtest +
      "&id_alattest=" +
      props.idAlattest +
      "&id_jadwal=" +
      DecodeJwt.id_jadwal +
      "&nip=" +
      DecodeJwt.nip,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          setJawaban(responseData.data[0])
          setkondisiSoal(false)
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        } else {
          setkondisiSoal(true)
          // Swal.fire({
          //   title: "Proses selesai!",
          //   icon: "success",
          //   timer: 1000,
          //   showConfirmButton: false,
          // })
        }
      })
      .catch(error => {
        setkondisiSoal(true)
        // Swal.fire({
        //   title: "Proses selesai!",
        //   icon: "success",
        //   timer: 1000,
        //   showConfirmButton: false,
        // })
      })
  }, [])

  const onDragEnd = result => {
    if (!result.destination) return

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    // Ensure the drag is within the same question
    if (result.type === "pilihan_jawaban") {
      const updatedQuestions = [...questions]
      const questionIndex = result.source.droppableId
      const sourceQuestion = updatedQuestions[questionIndex]
      const [draggedItem] = sourceQuestion.pilihan_jawaban.splice(
        sourceIndex,
        1
      )
      sourceQuestion.pilihan_jawaban.splice(destinationIndex, 0, draggedItem)

      setQuestions(updatedQuestions)

      console.log(updatedQuestions)

      // Update kunciArray along with questions
      const updatedKunciArray = [...kunciArray]
      updatedKunciArray[questionIndex] = sourceQuestion.pilihan_jawaban.map(
        answer => answer.kunci
      )
      console.log(data)
      const checkKunci = JSON.parse(data[destinationIndex].kunci_urutan)
      console.log('chekkunci' + checkKunci)
      const kunciString = updatedKunciArray[questionIndex].join(",")

      // Find the index of the matching item in 'checkKunci'
      const indexInCheckKunci = checkKunci.indexOf(kunciString)
      console.log(checkKunci)

      if (indexInCheckKunci !== -1) {
        const checkKunci = JSON.parse(data[destinationIndex].value_urutan)
        setJawaban(prevData => {
          const jawabanArray = [...prevData]
          jawabanArray[destinationIndex] = {
            id_msoal: data[destinationIndex].id_msoal,
            soal: data[destinationIndex].soal,
            value: checkKunci[indexInCheckKunci],
            id_soal_jawaban: null,
            no_soal: data[destinationIndex].no_soal,
            pilihan: kunciString,
            ju_id_indikator_jawaban: data[destinationIndex].id_indikator
          }
          return jawabanArray
        })
      } else {
        setJawaban(prevData => {
          const jawabanArray = [...prevData]
          jawabanArray[destinationIndex] = {
            id_msoal: data[destinationIndex].id_msoal,
            soal: data[destinationIndex].soal,
            value: "0",
            id_soal_jawaban: null,
            no_soal: data[destinationIndex].no_soal,
            pilihan: kunciString,
            ju_id_indikator_jawaban: data[destinationIndex].id_indikator
          }
          return jawabanArray
        })
      }
    }
  }

  // const HandlleNextPageAndSaveJawban = value => {
  //   setPage(value)

  //   // Swal.fire({
  //   //   title: "Proses data...",
  //   //   allowOutsideClick: false,
  //   //   onBeforeOpen: () => {
  //   //     Swal.showLoading()
  //   //   },
  //   //   showConfirmButton: false,
  //   // })

  //   var myHeaders = new Headers()
  //   myHeaders.append(
  //     "Authorization",
  //     "Bearer" + localStorage.getItem("access_token")
  //   )

  //   var formdata = new FormData()
  //   formdata.append("id_jadwal", DecodeJwt.id_jadwal)
  //   formdata.append("nip", DecodeJwt.nip)
  //   formdata.append("id_alattest", props.idAlattest)
  //   formdata.append("id_subtest", props.idSubtest)
  //   formdata.append("data", JSON.stringify(questions))

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   }

  //   fetch(
  //     process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawabanUrut",
  //     requestOptions
  //   )
  //     .then(response => response.json())
  //     .then(result => {
  //       if (result.meta.code == "200") {
  //         setQuestions(result.data[0])
  //         saveJawaban()
  //         //   Swal.fire({
  //         //     title: "Proses selesai!",
  //         //     icon: "success",
  //         //     timer: 1000,
  //         //     showConfirmButton: false,
  //         //   })
  //       }
  //     })
  //     .catch(error => {
  //       console.log("error", error)
  //       // Menutup SweetAlert jika terjadi kesalahan
  //       //   Swal.fire({
  //       //     title: "Terjadi kesalahan",
  //       //     icon: "error",
  //       //   })
  //     })
  // }

  const saveJawaban = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )
    var formdata = new FormData()
    formdata.append("id_jadwal", DecodeJwt.id_jadwal)
    formdata.append("nip", DecodeJwt.nip)
    formdata.append("id_alattest", props.idAlattest)
    formdata.append("id_subtest", props.idSubtest)
    formdata.append("data", JSON.stringify(jawaban))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        setJawaban(responseData.data[0])
      })
      .catch(error => {
        console.log("error", error)
        // Menutup SweetAlert jika terjadi kesalahan
        // Swal.fire({
        //   title: "Terjadi kesalahan",
        //   icon: "error",
        // })
      })
  }

  const saveAllData = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token")
    )
    var formdata = new FormData()
    formdata.append("id_jadwal", DecodeJwt.id_jadwal)
    formdata.append("nip", DecodeJwt.nip)
    formdata.append("id_alattest", props.idAlattest)
    formdata.append("id_subtest", props.idSubtest)
    formdata.append("data", JSON.stringify(jawaban))

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/simpanJawaban",
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          var myHeaders = new Headers()
          myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("access_token")
          )

          var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            redirect: "follow",
          }

          fetch(
            process.env.REACT_APP_BACKEND_URL_VERSION +
            "jawaban/updateStatus?idSubtest=" +
            idSubtest +
            "&idAlattest=" +
            props.idAlattest +
            "&nip=" +
            DecodeJwt.nip +
            "&id_jadwal=" +
            DecodeJwt.id_jadwal +
            "&id_asesi=" +
            DecodeJwt.id_jadwal_asesi,
            requestOptions
          )
            .then(response => response.text())
            .then(result => {
              const responseData = JSON.parse(result)
              if (responseData.meta.code == 200) {
                // Swal.fire({
                //   title: "Proses selesai!",
                //   icon: "success",
                //   timer: 1000, // Set the timer to 1000 milliseconds (1 second)
                //   showConfirmButton: false, // Hide the Confirm button
                // })
                navigate("/")
              }
            })
            .catch(error => console.log("error", error))
        }
      })
      .catch(error => {
        console.log("error", error)
        // Menutup SweetAlert jika terjadi kesalahan
        // Swal.fire({
        //   title: "Terjadi kesalahan",
        //   icon: "error",
        // })
      })
  }
  const handleSaveJawaban = () => {
    // Swal.fire({
    //   title: "Proses data...",
    //   text: "Mohon tunggu sebentar...", // Optional text to provide additional information
    //   allowOutsideClick: false,
    //   onBeforeOpen: () => {
    //     Swal.showLoading()
    //   },
    //   showConfirmButton: false,
    // })
    saveAllData()
  }
  const handleSaveJawabanPetunjuk = () => {
    setJawaban([])
    setkondisiSoal(false)
    setPage(1)
  }
  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
      setQuestions(soalPetunjuk)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
      setQuestions(dataSoal)
    }
  }, [kondisiSoal])

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  return (
    <div>
      <Row>
        <div className="alert alert-success w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            {kondisiSoal
              ? "Berikut Adalah petunjuk soal mengurutkan, tahan dan tarik keatas atau kebawah card untuk mengurutkan jawaban"
              : "Pastikan anda mengerjakan semua soal"}
          </p>
        </div>
        {props.waktu && kondisiSoal === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
              <MemoizedCountdownTimer durationInMinutes={props.waktu} setStatusTimer={setStatusTimer} />
            </p>
          </div>
        )}
      </Row>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        {questions &&
          questions.slice(startIndex, endIndex).map((item, index) => (
            <Row key={item.id_msoal} className="my-4">
              <Col xl={10}>
                <Card
                  className="shadow shadow-lg d-flex flex-column mh-25 h-100"
                  style={customLayout != null ? customLayoutCardSoal : null}
                >
                  <CardBody style={{ flex: 1, overflow: "auto" }}>
                    <p
                      style={customLayout != null ? customLayoutSoal : null}
                      className="fs-5 fw-medium"
                      dangerouslySetInnerHTML={{
                        __html: item.soal,
                      }}
                    ></p>
                    <div className="mt-3">
                      <hr size="5" width="100%" className="bg-light" />
                    </div>
                    <Droppable
                      droppableId={(startIndex + index).toString()}
                      type="pilihan_jawaban"
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {item.hasOwnProperty("pilihan_jawaban") &&
                            item.pilihan_jawaban.map((answer, answerIndex) => (
                              <Card
                                key={answer.id_soal_jawaban}
                                className="bg-light shadow shadow-sm"
                              >
                                <CardBody>
                                  <Draggable
                                    draggableId={answer.id_soal_jawaban.toString()}
                                    index={answerIndex}
                                    style={customLayout != null ? customLayoutCardJawaban : null}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <p
                                          style={customLayout != null ? customLayoutJawaban : null}
                                          className="fs-6 fw-semibold"
                                          dangerouslySetInnerHTML={{
                                            __html: answer.pilihan,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </CardBody>
                              </Card>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </CardBody>
                </Card>
                <Row>
                  <Col lg="12">
                    <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5 d-flex">
                      <PaginationItem disabled={page === 1} style={{ margin: '0 5px', flex: 2, display: 'flex', alignItems: 'center' }}>
                        <PaginationLink
                          className="fs-5"
                          style={{ borderRadius: "4px", flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          previous
                          onClick={(e) => {
                            e.preventDefault();
                            setPage(page - 1);
                          }}
                        >
                          <span style={{ display: 'inline-block', transform: 'rotate(180deg)' }}>&#10140;</span> Sebelumnya
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem disabled={page === totalPage} style={{ margin: '0 5px', flex: 2, display: 'flex', alignItems: 'center' }}>
                        <PaginationLink
                          className="fs-5"
                          style={{ borderRadius: "4px", flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          next
                          onClick={(e) => {
                            e.preventDefault();
                            setPage(page + 1);
                          }}
                        >
                          Selanjutnya &#10140;
                        </PaginationLink>
                      </PaginationItem>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col xl={2}>
                <HistorySoal
                  data={kondisiSoal ? soalPetunjuk : dataSoal}
                  selectedOptions={jawaban}
                  setSoal={setPage}
                />
              </Col>
            </Row>
          ))}
      </DragDropContext>

      {isLastPage && (
        <Row>
          <Col xl={12} style={{ marginTop: "10vh" }}>
            <Card className="shadow shadow-lg">
              <CardBody className="text-end">
                {kondisiSoal ? (
                  <button
                    className="btn-primary btn rounded rounded-3"
                    onClick={() => handleSaveJawabanPetunjuk()}
                  >
                    Mulai Kerjakan
                  </button>
                ) : (
                  <button
                    className="btn-primary btn rounded rounded-3"
                    onClick={() => handleSaveJawaban()}
                  >
                    Simpan Jawaban
                  </button>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default TipeB
