import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { map } from "lodash"
import { Badge, Card, CardBody, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, CardHeader } from "reactstrap"
import { slice } from "lodash"

const CardProject = props => {
  const currentPage = props.currentPage
  const dummyProjects = props.dummyProjects
  const itemsPerPage = props.itemsPerPage // Number of items to display per page
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const projectsToDisplay = slice(dummyProjects, startIndex, endIndex)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataDetail, setDataDetail] = useState()


  const toggle = (id_jadwal, id_asesi) => {
    setDataDetail()
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token"));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "list-proctoring?id_jadwal=" +
      id_jadwal +
      "&jadwal_asesi=" +
      id_asesi, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.meta.code === 200) {
          setDataDetail(result.data[0]);
        }
      })
      .catch(error => console.log('error', error));


    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const date = data => {
    const dateString = data
    const dateObj = new Date(dateString)

    const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`
    const formattedTime = `${dateObj
      .getHours()
      .toString()
      .padStart(2, "0")}:${dateObj
        .getMinutes()
        .toString()
        .padStart(2, "0")}:${dateObj.getSeconds().toString().padStart(2, "0")}`

    const formattedDateTime = `${formattedDate} ${formattedTime}`
    return formattedDateTime
  }

  return (
    <React.Fragment>
      {map(projectsToDisplay, (project, key) => (
        <Col xl="4" sm="6" key={key}>
          <Card onClick={() => toggle(project[0].mp_id_jadwal, project[0].mp_id_ja)}>
            <CardBody>
              <div className="d-flex justify-content-center align-items-center">
                {map(project, (projectDetail, key) => (
                  <img
                    key={key}
                    src={
                      projectDetail.mp_lokasi === "UTT"
                        ? process.env.REACT_APP_BACKEND_URL +
                        projectDetail.mp_link_capture
                        : process.env.REACT_APP_BACKEND_CAT_URL +
                        projectDetail.mp_link_capture
                    }
                    alt={projectDetail.mp_name}
                    style={{ width: "100px", height: "100px", marginLeft: "10px" }}
                  />
                ))}
              </div>
            </CardBody>
            <div className="px-4 py-3 border-top">
              <ul className="list-inline mb-0">
                <li className="list-inline-item me-3">
                  <Badge className={"bg-success"}>{project[0].mp_name}</Badge>
                </li>{" "}
                <li className="list-inline-item me-3" id="dueDate">
                  <i className="bx bx-calendar me-1" />
                  {date(project[0].updated_at)}
                  <UncontrolledTooltip placement="top" target="dueDate">
                    Last Update
                  </UncontrolledTooltip>
                </li>{" "}
                <li className="list-inline-item me-3" id="menu">
                  <i className="bx bx-menu me-1" /> {project.mp_nama_asesi}
                  <UncontrolledTooltip placement="top" target="menu">
                    {project[0].mp_nama_asesi}
                  </UncontrolledTooltip>
                </li>
                <li
                  className="list-inline-item me-3"
                  id="list"
                >
                  <i className="bx bx-detail me-1" /> {project[0].mp_nip}
                </li>
              </ul>
            </div>
            {/* <div className="d-flex justify-content-center align-items-center">
              
            </div> */}
          </Card>
        </Col>
      ))}

      <Modal isOpen={modal} toggle={toggle} size="lg" style={{ maxWidth: '1000px', width: '100%' }}>
        <ModalHeader toggle={toggle} tag="h4">
          History Proctoring
        </ModalHeader>
        <ModalBody style={{ maxHeight: '700px', overflowY: 'auto' }} >
          <Row>
            <Col className="col-12 my-1">
              {dataDetail ? dataDetail.map((item, index) => (
                <React.Fragment key={index} >
                  <Card className="my-1">
                    <CardBody>
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={
                            item.mp_lokasi === "UTT"
                              ? process.env.REACT_APP_BACKEND_URL + item.mp_link_capture
                              : process.env.REACT_APP_BACKEND_CAT_URL + item.mp_link_capture
                          }
                          alt={item.mp_name}
                          style={{ width: "100%", height: "auto" }}
                        />
                      </div>
                    </CardBody>
                    <div className="px-4 py-3 border-top">
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item me-3">
                          <Badge className={"bg-success"}>{item.mp_name}</Badge>
                        </li>{" "}
                        <li className="list-inline-item me-3" id="dueDate">
                          <i className="bx bx-calendar me-1" />
                          {date(item.updated_at)}
                          <UncontrolledTooltip placement="top" target="dueDate">
                            Last Update
                          </UncontrolledTooltip>
                        </li>{" "}
                        <li className="list-inline-item me-3" id="menu">
                          <i className="bx bx-menu me-1" /> {item.mp_nama_asesi}
                          <UncontrolledTooltip placement="top" target="menu">
                            {item.mp_nama_asesi}
                          </UncontrolledTooltip>
                        </li>
                      </ul>
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      {item.mp_nip}
                    </div>
                  </Card>

                </React.Fragment>
              )) : (
                <>
                  <h5>loading ....</h5>
                </>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

CardProject.propTypes = {
  projects: PropTypes.array,
}

export default CardProject
