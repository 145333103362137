import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const soal_rating = ({
  data,
  idAlattest,
  idSubtest,
  dataSoal,
  soalPetunjuk,
  waktu,
  customLayout
}) => {
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()

  const [selectedOptions, setSelectedOptions] = useState([])
  const [error, setError] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState()
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    kunci,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptions]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item => item.id_msoal === id_msoal
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      kunci,
      ju_id_indikator_jawaban
    }

    if (existingItemIndex !== -1) {
      updatedSelectedOptions[existingItemIndex] = newItem
    } else {
      updatedSelectedOptions.push(newItem)
    }

    setSelectedOptions(updatedSelectedOptions)
  }

  useEffect(() => {
    if (kondisiSoal && soalPetunjuk.length > 0) {
      setDataPage(soalPetunjuk.length)
    } else {
      setDataPage(dataSoal.length)
    }
  }, [kondisiSoal])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <div className="alert alert-success w-100">
            <div className="d-flex align-items-center">
              <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
                <i
                  className="mdi mdi-alert-circle"
                  style={{ lineHeight: "1" }}
                ></i>{" "}
                {kondisiSoal
                  ? "Berikut Adalah Contoh Soal"
                  : "Pastikan anda mengisi semua soal"}
              </p>
            </div>
          </div>
          {waktu && kondisiSoal === false && (
            <div className="alert alert-primary d-flex align-items-center justify-content-center">
              <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
                <MemoizedCountdownTimer durationInMinutes={waktu} setStatusTimer={setStatusTimer} />
              </p>
            </div>
          )}
        </Row>

        <div className="mt-3">
          <hr size="5" width="100%" className="bg-light" />
        </div>
        <Row>
          <Col xl={10}>
            {soalPetunjuk.length > 0 && kondisiSoal
              ? soalPetunjuk.slice(startIndex, endIndex).map((item, index) => (
                <Card
                  className="border shadow shadow-lg"
                  key={startIndex + index}
                  style={customLayout != null ? customLayoutCardSoal : null}
                >
                  <CardBody>
                    <div
                      className="card-title"
                      dangerouslySetInnerHTML={{
                        __html: item.soal,
                      }}
                    />
                    <div className="card-text text-center">
                      <div className="d-flex justify-content-center flex-wrap bd-highlight">
                        {item.pilihan_jawaban.map((item2, index2) => (
                          <div key={index2}>
                            <label className="card-radio-label shadow shadow-lg">
                              <input
                                type="radio"
                                name={`pilihan_${startIndex + index}`}
                                onClick={() =>
                                  handleRadioChange(
                                    item.id_msoal,
                                    item.mt_idsubtest,
                                    item.no_soal,
                                    item.soal,
                                    item2.id_soal_jawaban,
                                    item2.pilihan,
                                    item2.value,
                                    item2.id_indikator
                                  )
                                }
                                className="card-radio-input"
                                checked={selectedOptions.some(
                                  option =>
                                    option.id_msoal === item.id_msoal &&
                                    option.id_soal_jawaban ===
                                    item2.id_soal_jawaban
                                )}
                              />

                              <div className="card-radio border border-primary border-1">
                                <div
                                  style={customLayout != null ? customLayoutSoal : null}
                                  className="mt-2 ms-4 me-4"
                                  dangerouslySetInnerHTML={{
                                    __html: item2.pilihan,
                                  }}
                                ></div>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <Col xl={12}>
                      <div className="row justify-content-lg-center">
                        <div className="col col-sm-2 me-auto fw-bold">
                          NOT AT ALL LIKELY
                        </div>
                        <div className="col-lg-2 me-2">&nbsp;</div>
                        <div className="col col-sm-2 ms-auto fw-bold">
                          EXTREAMLY LIKELY
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              ))
              : dataSoal.slice(startIndex, endIndex).map((item, index) => (
                <Card
                  className="border shadow shadow-lg"
                  key={startIndex + index}
                  style={customLayout != null ? customLayoutCardSoal : null}
                >
                  <CardBody>
                    <div
                      style={customLayout != null ? customLayoutSoal : null}
                      className="card-title"
                      dangerouslySetInnerHTML={{
                        __html: item.soal,
                      }}
                    />
                    <div className="card-text text-center">
                      <div className="d-flex justify-content-center flex-wrap bd-highlight">
                        {item.pilihan_jawaban.map((item2, index2) => (
                          <div key={index2}>
                            <label
                              className="card-radio-label shadow shadow-lg"
                              style={customLayout != null ? customLayoutCardJawaban : null}
                            >
                              <input
                                type="radio"
                                name={`pilihan_${startIndex + index}`}
                                onClick={() =>
                                  handleRadioChange(
                                    item.id_msoal,
                                    item.mt_idsubtest,
                                    item.no_soal,
                                    item.soal,
                                    item2.id_soal_jawaban,
                                    item2.pilihan,
                                    item2.value,
                                    item2.id_indikator
                                  )
                                }
                                className="card-radio-input"
                                checked={selectedOptions.some(
                                  option =>
                                    option.id_msoal === item.id_msoal &&
                                    option.id_soal_jawaban ===
                                    item2.id_soal_jawaban
                                )}
                              />

                              <div className="card-radio border border-primary border-1">
                                <div
                                  style={customLayout != null ? customLayoutJawaban : null}
                                  className="mt-2 ms-4 me-4"
                                  dangerouslySetInnerHTML={{
                                    __html: item2.pilihan,
                                  }}
                                ></div>
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>

                    <Col xl={12}>
                      <div className="row justify-content-lg-center">
                        <div className="col col-sm-2 me-auto fw-bold">
                          NOT AT ALL LIKELY
                        </div>
                        <div className="col-lg-2 me-2">&nbsp;</div>
                        <div className="col col-sm-2 ms-auto fw-bold">
                          EXTREAMLY LIKELY
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              ))}

            {/* <Row>
              <Col lg="12">
                <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                  <PaginationItem disabled={page === 1}>
                    <PaginationLink
                      previous
                      href="#"
                      onClick={() => setPage(page - 1)}
                    />
                  </PaginationItem>

                  {Array.from({ length: totalPage }).map((_, i) => (
                    <PaginationItem active={i + 1 === page} key={i}>
                      <PaginationLink onClick={() => setPage(i + 1)} href="#">
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                  <PaginationItem disabled={page === totalPage}>
                    <PaginationLink
                      next
                      href="#"
                      onClick={() => setPage(page + 1)}
                    />
                  </PaginationItem>
                </ul>
              </Col>
            </Row> */}
          </Col>
          <Col xl={2}>
            <HistorySoal
              data={kondisiSoal ? soalPetunjuk : dataSoal}
              selectedOptions={selectedOptions}
              setSoal={setPage}
            />
          </Col>
        </Row>
        {/* {isLastPage || statusTImer && (
          <Row>
            <SaveJawaban
              idAlattest={idAlattest}
              idSubtest={idSubtest}
              id_jadwal={DecodeJwt.id_jadwal}
              nip={DecodeJwt.nip}
              id_asesi={DecodeJwt.id_jadwal_asesi}
              kondisiSoal={kondisiSoal}
              setKondisiSoal={setkondisiSoal}
              setPage={setPage}
              setSelectedOptions={setSelectedOptions}
              statusTImer={statusTImer}
            />
          </Row>
        )} */}
      </Container>
    </div>
  )
}

export default soal_rating
