import { Container, Row, Col, CardBody, Card, Button } from "reactstrap"
import Navbar from "components/HorizontalLayout/Navbar"
import TipeA from "./tipeA"
import React, { useState, useEffect } from "react"

const soal_bei = (props) => {
  return <TipeA
    data={props.data}
    idAlattest={props.idAlattest}
    idSubtest={props.idSubtest}
    dataSoal={props.dataSoal}
    soalPetunjuk={props.soalPetunjuk}
    waktu={props.waktu}
    customLayout={props.customLayout}
  />
}

export default soal_bei
