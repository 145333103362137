import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  CardHeader,
  Progress,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { Link, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import { setOptions } from "leaflet"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const tipeA = props => {
  const data = props.data
  const idAlattest = props.idAlattest
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const waktu = props.waktu
  const customLayout = props.customLayout

  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()
  const [no, setNo] = useState(1)
  // const [progress, setProgress] = useState(25)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState(false)
  const [statusTImer, setStatusTimer] = useState(false)

  const handleChangeData = (
    value,
    id_nextsoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    id_msoal,
    id_indikator
  ) => {
    const updatedSelectedOptions = [...selectedOptions]

    const existingItemIndex = updatedSelectedOptions.findIndex(
      item => item.pilihan === pilihan
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      id_indikator,
    }

    if (existingItemIndex !== -1) {
      updatedSelectedOptions[existingItemIndex] = newItem
    } else {
      updatedSelectedOptions.push(newItem)
    }

    setSelectedOptions(updatedSelectedOptions)

    if (id_nextsoal && id_nextsoal !== 0) {
      setNo(id_nextsoal)
    } else {
      setNo(0)
    }
  }

  const handleSaveJawaban = () => {
  }
  const sortedDataSoal = _.sortBy(dataSoal, ["id_msoal"])
  const sortedDataPetunjuk = _.sortBy(soalPetunjuk, ["id_msoal"])

  const handleMulaiMengerjakan = () => {
    setkondisiSoal(false)
    setSelectedOptions([])
  }


  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  useEffect(() => {
    if (soalPetunjuk.length > 0) {
      setkondisiSoal(true)
    } else {
      setkondisiSoal(false)
    }
  }, [])

  useEffect(() => {
    setNo(1)
  }, [kondisiSoal])

  console.log("makanan");


  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };


  return (
    <div>
      <Row>
        <div className="alert alert-success w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            {soalPetunjuk.length > 0 && kondisiSoal ? (
              <>
                <b>Berikut Adalah Soal Petunjuk </b><br />
                <br />
                Soal pilihan ganda dengan 2 sampai 4 pilihan jawaban tiap
                nomornya. Setiap pilihan jawaban yang tersedia, memiliki
                kemungkinan mewakili satu atau lebih indikator dari kompetensi
                terkait. Setiap jawaban memiliki tingkatan untuk melanjutkan ke
                soal berikutnya berdasarkan tingkatannya.
              </>
            ) : (
              <>
                <b>Soal Bertingkat</b> <br /><br />
                Soal pilihan ganda dengan 2 sampai 4 pilihan jawaban tiap
                nomornya. Setiap pilihan jawaban yang tersedia, memiliki
                kemungkinan mewakili satu atau lebih indikator dari kompetensi
                terkait. Setiap jawaban memiliki tingkatan untuk melanjutkan ke
                soal berikutnya berdasarkan tingkatannya.
              </>
            )}
          </p>
        </div>
        {waktu && kondisiSoal === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
              <MemoizedCountdownTimer durationInMinutes={waktu} setStatusTimer={setStatusTimer} />
            </p>
          </div>
        )}
      </Row>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row className="justify-content-center align-items-center">
        <Col xs={10} className="text-center">
          {soalPetunjuk.length > 0 && kondisiSoal ? (
            <Card
              className="border border-2 border-primary shadow shadow-lg"
              style={customLayout != null ? customLayoutCardSoal : null}
            >
              <CardBody>
                {no !== 0 ? (
                  sortedDataPetunjuk.map((item, index) => (
                    <div key={index}>
                      {no === 1 && index + 1 === 1 ? (
                        <>
                          <p
                            style={customLayout != null ? customLayoutSoal : null}
                            className="fs-5 fw-semibold mt-3 mb-3"
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          ></p>
                          <hr
                            className="border border-1 border-primary border-opacity-25"
                            width="100%"
                          />
                          {item.pilihan_jawaban.map((jawabanItem, index2) => (
                            <div key={index2}>
                              <label className="card-radio-label">
                                <input
                                  type="radio"
                                  id={jawabanItem.id_soal_jawaban}
                                  name={`pilihan_` + index}
                                  onChange={() =>
                                    handleChangeData(
                                      jawabanItem.value,
                                      jawabanItem.id_nextsoal,
                                      item.mt_idsubtest,
                                      item.no_soal,
                                      item.soal,
                                      jawabanItem.id_soal_jawaban,
                                      jawabanItem.pilihan,
                                      item.id_msoal,
                                      jawabanItem.id_indikator
                                    )
                                  } // Handle change event
                                  className="card-radio-input"
                                />

                                <div
                                  className="card-radio rounded-pill border-5 p-3 border-primary "
                                  style={customLayout != null ? customLayoutCardJawaban : null}
                                >
                                  <div className="d-flex align-items-center justify-content-center fs-5">
                                    <span className="p-0 m-0">
                                      <div
                                        style={customLayout != null ? customLayoutCardJawaban : null}
                                        dangerouslySetInnerHTML={{
                                          __html: jawabanItem.pilihan.replace(
                                            "<p>",
                                            '<p style="margin: 0;">'
                                          ),
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}
                        </>
                      ) : (
                        item.id_msoal === no && (
                          <>
                            <p
                              style={customLayout != null ? customLayoutSoal : null}
                              className="fs-4 fw-bold mt-3 mb-3"
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            ></p>
                            {item.pilihan_jawaban.map((jawabanItem, index2) => (
                              <div key={index2}>
                                <label className="card-radio-label mb-2">
                                  <input
                                    type="radio"
                                    id={jawabanItem.id_soal_jawaban}
                                    name={`pilihan_` + index}
                                    onChange={() =>
                                      handleChangeData(
                                        jawabanItem.value,
                                        jawabanItem.id_nextsoal,
                                        item.mt_idsubtest,
                                        item.no_soal,
                                        item.soal,
                                        jawabanItem.id_soal_jawaban,
                                        jawabanItem.pilihan,
                                        item.id_msoal,
                                        jawabanItem.id_indikator
                                      )
                                    } // Handle change event
                                    className="card-radio-input"
                                  />
                                  <div
                                    className="card-radio rounded-pill border-5 p-3 border-primary "
                                    style={customLayout != null ? customLayoutCardJawaban : null}
                                  >
                                    <div className="d-flex align-items-center justify-content-center fs-5 text-wrap">
                                      <span className="p-0 m-0">
                                        <div
                                          style={customLayout != null ? customLayoutJawaban : null}
                                          dangerouslySetInnerHTML={{
                                            __html: jawabanItem.pilihan.replace(
                                              "<p>",
                                              '<p style="margin: 0;">'
                                            ),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ))}
                          </>
                        )
                      )}
                    </div>
                  ))
                ) : (
                  <Row>
                    <Col xl={12}>
                      <label className="card-radio-label fs-4">
                        Anda telah mengerjakan soal petunjuk klik mulai kerjakan
                        untuk melanjukan pengerjaan soal
                      </label>
                      <button
                        className="btn-primary btn rounded rounded-3"
                        onClick={() => handleMulaiMengerjakan()}
                      >
                        Mulai Kerjakan
                      </button>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          ) : (
            <Card
              className="border border-2 border-primary shadow shadow-lg"
              style={customLayout != null ? customLayoutCardSoal : null}
            >
              <CardBody>
                {no !== 0 ? (
                  sortedDataSoal.map((item, index) => (
                    <div key={index}>
                      {no === 1 && index + 1 === 1 ? (
                        <>
                          <p
                            style={customLayout != null ? customLayoutSoal : null}
                            className="fs-5 fw-semibold mt-3 mb-3"
                            dangerouslySetInnerHTML={{
                              __html: item.soal,
                            }}
                          ></p>
                          <hr
                            className="border border-1 border-primary border-opacity-25"
                            width="100%"
                          />
                          {item.pilihan_jawaban.map((jawabanItem, index2) => (
                            <div key={index2}>
                              <label className="card-radio-label">
                                <input
                                  type="radio"
                                  id={jawabanItem.id_soal_jawaban}
                                  name={`pilihan_` + index}
                                  onChange={() =>
                                    handleChangeData(
                                      jawabanItem.value,
                                      jawabanItem.id_nextsoal,
                                      item.mt_idsubtest,
                                      item.no_soal,
                                      item.soal,
                                      jawabanItem.id_soal_jawaban,
                                      jawabanItem.pilihan,
                                      item.id_msoal,
                                      jawabanItem.id_indikator
                                    )
                                  } // Handle change event
                                  className="card-radio-input"
                                />

                                <div
                                  className="card-radio rounded-pill border-5 p-3 border-primary "
                                  style={customLayout != null ? customLayoutCardJawaban : null}
                                >
                                  <div className="d-flex align-items-center justify-content-center fs-5 text-wrap">
                                    <span className="p-0 m-0">
                                      <div
                                        style={customLayout != null ? customLayoutJawaban : null}
                                        dangerouslySetInnerHTML={{
                                          __html: jawabanItem.pilihan.replace(
                                            "<p>",
                                            '<p style="margin: 0;">'
                                          ),
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </label>
                            </div>
                          ))}
                        </>
                      ) : (
                        item.id_msoal === no && (
                          <>
                            <p
                              style={customLayout != null ? customLayoutSoal : null}
                              className="fs-4 fw-bold mt-3 mb-3"
                              dangerouslySetInnerHTML={{
                                __html: item.soal,
                              }}
                            ></p>
                            {item.pilihan_jawaban.map((jawabanItem, index2) => (
                              <div key={index2}>
                                <label className="card-radio-label mb-2">
                                  <input
                                    type="radio"
                                    id={jawabanItem.id_soal_jawaban}
                                    name={`pilihan_` + index}
                                    onChange={() =>
                                      handleChangeData(
                                        jawabanItem.value,
                                        jawabanItem.id_nextsoal,
                                        item.mt_idsubtest,
                                        item.no_soal,
                                        item.soal,
                                        jawabanItem.id_soal_jawaban,
                                        jawabanItem.pilihan,
                                        item.id_msoal,
                                        jawabanItem.id_indikator
                                      )
                                    } // Handle change event
                                    className="card-radio-input"
                                  />
                                  <div
                                    className="card-radio rounded-pill border-5 p-3 border-primary "
                                    style={customLayout != null ? customLayoutCardJawaban : null}
                                  >
                                    <div className="d-flex align-items-center justify-content-center fs-5">
                                      <span className="p-0 m-0">
                                        <div
                                          style={customLayout != null ? customLayoutSoal : null}
                                          dangerouslySetInnerHTML={{
                                            __html: jawabanItem.pilihan.replace(
                                              "<p>",
                                              '<p style="margin: 0;">'
                                            ),
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </label>
                              </div>
                            ))}
                          </>
                        )
                      )}
                    </div>
                  ))
                ) : (
                  <Row>
                    <Col xl={12}>
                      <label className="card-radio-label fs-4">
                        Anda telah mengerjakan semua soal
                      </label>
                      <button
                        className="btn-primary btn rounded rounded-3"
                        onClick={() => handleSaveJawaban()}
                      >
                        Selesai
                      </button>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default tipeA
