import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const index = props => {

  const idAlattest = props.idAlattest
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const waktu = props.waktu
  const customLayout = props.customLayout

  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()

  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionsPetunjuk, setSelectedOptionsPetunjuk] = useState([])
  const [error, setError] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState(false)
  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)
  const [data, setdata] = useState([])
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    ju_id_indikator_jawaban,
    kunci,
    skala
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptions]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item => item.id_msoal === id_msoal
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      ju_id_indikator_jawaban,
      kunci,
      skala,
    }

    if (existingItemIndex !== -1) {
      updatedSelectedOptions[existingItemIndex] = newItem
    } else {
      updatedSelectedOptions.push(newItem)
    }

    setSelectedOptions(updatedSelectedOptions)
  }

  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    ju_id_indikator_jawaban,
    kunci,
    skala
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptionsPetunjuk]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item => item.id_msoal === id_msoal
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      ju_id_indikator_jawaban,
      kunci,
      skala,
    }

    if (existingItemIndex !== -1) {
      updatedSelectedOptions[existingItemIndex] = newItem
    } else {
      updatedSelectedOptions.push(newItem)
    }

    setSelectedOptionsPetunjuk(updatedSelectedOptions)
  }

  useEffect(() => {
    if (soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
      setdata(soalPetunjuk)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
      setdata(dataSoal)
    }
  }, [kondisiSoal])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  return (
    <Container fluid>
      <Row>
        <div className="alert alert-success w-100">
          <div className="d-flex align-items-center">
            <p className="fs-5 fw-medium m-3" style={{ lineHeight: "2" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "2" }}
              ></i>{" "}
              {kondisiSoal
                ? "Berikut Adalah Soal Petunjuk"
                : "Pastikan anda mengisi semua soal"}
            </p>
          </div>
        </div>
        {waktu && kondisiSoal === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
              <MemoizedCountdownTimer durationInMinutes={waktu} setStatusTimer={setStatusTimer} />
            </p>
          </div>
        )}
      </Row>

      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <Row>
        <Col xl={10} >
          {data && data.slice(startIndex, endIndex).map((item, index) => (

            <Card
              className="border border-secondary shadow shadow-lg"
              key={startIndex + index}
              style={customLayout != null ? customLayoutCardSoal : null}
            >
              <CardBody>
                <div
                  style={customLayout != null ? customLayoutSoal : null}
                  className="card-title"
                  dangerouslySetInnerHTML={{
                    __html: item.soal,
                  }}
                />
                <div className="mt-3">
                  <hr size="5" width="100%" className="bg-light" />
                </div>
                <Row className="text-center mt-3 d-flex flex-row justify-content-center">
                  <Col xl={2} className="text-center">
                    <p className="fs-5 fw-semibold" style={{ maxWidth: "200px" }}>
                      {JSON.parse(item.skala)[0]}
                    </p>
                  </Col>
                  <Col xl={8}>
                    <div className="d-flex flex-row justify-content-center">
                      {item.pilihan_jawaban.map((item2, index2) => (
                        <div key={index2} className="ms-4 me-4">
                          <input
                            type="radio"
                            className="form-check-input border border-2 border-primary"
                            name={`pilihan_${startIndex + index}`}
                            onClick={() => {
                              if (kondisiSoal) {
                                handleRadioChangePetunjuk(
                                  item.id_msoal,
                                  item.mt_idsubtest,
                                  item.no_soal,
                                  item.soal,
                                  item2.id_soal_jawaban,
                                  item2.pilihan,
                                  item2.value,
                                  item2.id_indikator,
                                  item2.is_kunci,
                                  JSON.parse(item.skala)[index2]
                                )
                              } else {
                                handleRadioChange(
                                  item.id_msoal,
                                  item.mt_idsubtest,
                                  item.no_soal,
                                  item.soal,
                                  item2.id_soal_jawaban,
                                  item2.pilihan,
                                  item2.value,
                                  item2.id_indikator,
                                  item2.is_kunci,
                                  JSON.parse(item.skala)[index2]
                                )
                              }
                            }
                            }
                            checked={
                              kondisiSoal ?
                                selectedOptionsPetunjuk.some(
                                  option =>
                                    option.id_msoal === item.id_msoal &&
                                    option.id_soal_jawaban === item2.id_soal_jawaban
                                ) :
                                selectedOptions.some(
                                  option =>
                                    option.id_msoal === item.id_msoal &&
                                    option.id_soal_jawaban === item2.id_soal_jawaban
                                )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </Col>
                  <Col xl={2} className="text-right">
                    <p className="fs-5 fw-semibold" style={{ maxWidth: "200px" }}>
                      {JSON.parse(item.skala)[1]}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ))}
          {/* <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {Array.from({ length: totalPage }).map((_, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink onClick={() => setPage(i + 1)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row> */}
        </Col>
        <Col xl={2}>
          <HistorySoal
            data={data}
            selectedOptions={
              kondisiSoal ? selectedOptionsPetunjuk : selectedOptions
            }
            setSoal={setPage}
          />
        </Col>
      </Row>
      {/* {(isLastPage || statusTImer === true) && (
        <Row>
          <SaveJawaban
            idAlattest={idAlattest}
            idSubtest={idSubtest}
            id_jadwal={DecodeJwt.id_jadwal}
            nip={DecodeJwt.nip}
            id_asesi={DecodeJwt.id_jadwal_asesi}
            kondisiSoal={kondisiSoal}
            setKondisiSoal={setkondisiSoal}
            setPage={setPage}
            statusTImer={statusTImer}
          />
        </Row>
      )} */}
    </Container>
  )
}

export default index
