import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Label,
  Input,
  PaginationLink,
  PaginationItem,
  CardHeader,
  Collapse,
} from "reactstrap"
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Loading from "components/Loading/loading"
import Swal from "sweetalert2"
import SaveJawaban from "common/HandleSaveJawaban/save_jawaban"
import HistorySoal from "components/HistorySoal/history_soal"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
import classnames from "classnames"
const MemoizedCountdownTimer = React.memo(CountdownTimer)

const tipeA = props => {
  const idAlattest = props.idAlattest
  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const waktu = props.waktu
  const customLayout = props.customLayout
  const subTest = props.subTest
  const showInstruksi = props.showInstruksi
  const showTimer = props.showTimer
  const isExpand = props.isExpand
  const onExpandToggle = props.onExpandToggle
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))
  const navigate = useNavigate()

  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedOptionspetunjuk, setSelectedOptionspetunjuk] = useState([])
  const [error, setError] = useState(false)
  const [kondisiSoal, setkondisiSoal] = useState()
  const [page, setPage] = useState(1)
  const [data, setdata] = useState([])
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const handleRadioChange = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    id_aspekat,
    skala,
    index,
    ju_id_indikator_jawaban
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptions]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      id_aspekat,
      skala: skala[index],
      index,
      ju_id_indikator_jawaban,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          console.log(
            "Skala sudah di Inputkan, pilih skala lain atau ubah skala"
          )
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          console.log(
            "Skala sudah di Inputkan, pilih skala lain atau ubah skala1"
          )
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptions(updatedSelectedOptions)
  }
  const handleRadioChangePetunjuk = (
    id_msoal,
    mt_idsubtest,
    no_soal,
    soal,
    id_soal_jawaban,
    pilihan,
    value,
    id_aspekat,
    skala,
    index
  ) => {
    // Clone the existing selectedOptions array
    const updatedSelectedOptions = [...selectedOptionspetunjuk]

    // Check if an item with the same skala and pilihan already exists
    const existingItemIndex = updatedSelectedOptions.findIndex(
      item =>
        item.id_soal_jawaban === id_soal_jawaban && item.id_msoal === id_msoal
    )
    const indexExists = updatedSelectedOptions.some(
      item => item.index === index && item.id_msoal === id_msoal
    )
    const indexExistsUncheck = updatedSelectedOptions.some(
      item =>
        item.index === index &&
        item.id_msoal === id_msoal &&
        item.id_soal_jawaban === id_soal_jawaban
    )

    const newItem = {
      id_msoal,
      mt_idsubtest,
      no_soal,
      soal,
      id_soal_jawaban,
      pilihan,
      value,
      id_aspekat,
      skala: skala[index],
      index,
    }

    if (indexExistsUncheck) {
      // Remove the item when indexExistsUncheck is true
      const removeIndex = updatedSelectedOptions.findIndex(
        item =>
          item.index === index &&
          item.id_msoal === id_msoal &&
          item.id_soal_jawaban === id_soal_jawaban
      )
      updatedSelectedOptions.splice(removeIndex, 1)
    } else {
      if (existingItemIndex !== -1) {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions[existingItemIndex] = newItem
        }
      } else {
        if (indexExists) {
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 7000)
        } else {
          updatedSelectedOptions.push(newItem)
        }
      }
    }

    setSelectedOptionspetunjuk(updatedSelectedOptions)
  }

  useEffect(() => {
    if (soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
      setdata(soalPetunjuk)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
      setdata(dataSoal)
    }
  }, [kondisiSoal])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  }

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  }

  const [openAccordions, setOpenAccordions] = useState(true)

  const toggleAccordion = () => {
    setOpenAccordions(openAccordions === true ? false : true)
  }

  const [soalState, setSoalState] = useState(9)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const expand = () => {
    setSoalState(soalState === 9 ? 12 : 9)
    setPetunjukStatus(petunjukStatus === true ? false : true)
    onExpandToggle()
  }

  return (
    <div>
      <Row>
        {error ? (
          <div className="alert alert-danger w-100 d-flex align-items-center shake">
            <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
              <i
                className="mdi mdi-alert-circle"
                style={{ lineHeight: "1" }}
              ></i>{" "}
              Skala sudah di Inputkan, pilih skala lain atau ubah skala
            </p>
          </div>
        ) : (
          !showInstruksi && (
            <div className="accordion m-3" id="accordion">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !openAccordions,
                    })}
                    type="button"
                    onClick={() => toggleAccordion()}
                    style={{ cursor: "pointer" }}
                  >
                    Instruksi
                  </button>
                </h2>

                <Collapse
                  isOpen={openAccordions}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <CardBody className="bg-light rounded rounded-3">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subTest?.instruksi,
                        }}
                      />
                    </CardBody>
                  </div>
                </Collapse>
              </div>
            </div>
          )
        )}
        {waktu && kondisiSoal && !showTimer && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>

      <Row>
        <Col xl={soalState}>
          {data.slice(startIndex, endIndex).map((item, outerIndex) => (
            <Card
              className="shadow shadow-lg d-flex flex-column mh-25 full-height"
              key={outerIndex}
              style={customLayout != null ? customLayoutCardSoal : null}
            >
              <CardHeader className="d-flex border-bottom border-4 bg-primary justify-content-between align-items-center text-white">
                <div className="flex-grow-1">
                  <p
                    style={customLayout != null ? customLayoutSoal : null}
                    className="card-title"
                    dangerouslySetInnerHTML={{
                      __html: item.soal,
                    }}
                  ></p>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    expand()
                  }}
                  className="btn noti-icon text-white float-end"
                >
                  <i className="bx bx-fullscreen" style={{ color: "white" }} />
                </button>
              </CardHeader>
              <CardBody>
                <table className="table table-striped">
                  <thead>
                    <tr className="text-center fw-bold">
                      <th></th>
                      {JSON.parse(item.skala).map((item2, index2) => (
                        <th key={index2}>
                          {" "}
                          <div
                            style={
                              customLayout != null ? customLayoutSoal : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item2,
                            }}
                          ></div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {item.pilihan_jawaban.map((item3, innerIndex) => (
                      <tr className="text-center fw-bold" key={innerIndex}>
                        <td>
                          <div
                            style={
                              customLayout != null ? customLayoutJawaban : null
                            }
                            dangerouslySetInnerHTML={{
                              __html: item3.pilihan,
                            }}
                          ></div>
                        </td>
                        {JSON.parse(item3.value).map((item4, index4) => (
                          <td key={index4}>
                            <input
                              type="radio"
                              className="form-check-input border border-2 border-primary"
                              name={`pilihan_${outerIndex}${innerIndex}`}
                              onClick={() => {
                                if (kondisiSoal) {
                                  handleRadioChangePetunjuk(
                                    item.id_msoal,
                                    item.mt_idsubtest,
                                    item.no_soal,
                                    item.soal,
                                    item3.id_soal_jawaban,
                                    item3.pilihan,
                                    item4,
                                    item3.id_aspekat,
                                    JSON.parse(item.skala),
                                    index4,
                                    item3.id_indikator
                                  )
                                } else {
                                  handleRadioChange(
                                    item.id_msoal,
                                    item.mt_idsubtest,
                                    item.no_soal,
                                    item.soal,
                                    item3.id_soal_jawaban,
                                    item3.pilihan,
                                    item4,
                                    item3.id_aspekat,
                                    JSON.parse(item.skala),
                                    index4,
                                    item3.id_indikator
                                  )
                                }
                              }}
                              checked={
                                kondisiSoal
                                  ? selectedOptionspetunjuk.some(
                                      option =>
                                        option.id_msoal === item.id_msoal &&
                                        option.id_soal_jawaban ===
                                          item3.id_soal_jawaban &&
                                        option.index === index4
                                    )
                                  : selectedOptions.some(
                                      option =>
                                        option.id_msoal === item.id_msoal &&
                                        option.id_soal_jawaban ===
                                          item3.id_soal_jawaban &&
                                        option.index === index4
                                    )
                              }
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBody>
            </Card>
          ))}
          {/* <Row>
            <Col lg="12">
              <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                <PaginationItem disabled={page === 1}>
                  <PaginationLink
                    previous
                    href="#"
                    onClick={() => setPage(page - 1)}
                  />
                </PaginationItem>

                {Array.from({ length: totalPage }).map((_, i) => (
                  <PaginationItem active={i + 1 === page} key={i}>
                    <PaginationLink onClick={() => setPage(i + 1)} href="#">
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem disabled={page === totalPage}>
                  <PaginationLink
                    next
                    href="#"
                    onClick={() => setPage(page + 1)}
                  />
                </PaginationItem>
              </ul>
            </Col>
          </Row> */}
        </Col>
        <Col xl={3}>
          {petunjukStatus && (
            <HistorySoal
              data={data}
              selectedOptions={
                kondisiSoal ? selectedOptionspetunjuk : selectedOptions
              }
              setSoal={setPage}
            />
          )}
        </Col>
      </Row>
      {/* {(isLastPage || statusTImer === true) && (
        <Row>
          <SaveJawaban
            idAlattest={idAlattest}
            idSubtest={idSubtest}
            id_jadwal={DecodeJwt.id_jadwal}
            nip={DecodeJwt.nip}
            id_asesi={DecodeJwt.id_jadwal_asesi}
            kondisiSoal={kondisiSoal}
            setKondisiSoal={setkondisiSoal}
            setPage={setPage}
            statusTImer={statusTImer}
          />
        </Row>
      )} */}
    </div>
  )
}

export default tipeA
