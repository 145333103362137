import { Container, Row, Col, CardBody, Card, Button } from "reactstrap"
import Navbar from "components/HorizontalLayout/Navbar"
import TipeA from "./tipeA"
import TipeB from "./tipeB"
import React, { useState, useEffect } from "react"
const index = props => {
  // const [view, SetView] = useState(false)

  // const tampil = props => {
  //   if (view == true) {
  //     SetView(false)
  //   } else {
  //     SetView(true)
  //   }
  // }

  return (
    <div>
      {/* <button className="btn btn-primary m-3" onClick={tampil}>
        <i className="mdi-eye mdi"></i>
      </button> */}
      {/* {view === true ? <TipeA data={props.data} /> : <TipeB data={props.data} />} */}
      <TipeB
        data={props.data}
        idAlattest={props.idAlattest}
        idSubtest={props.idSubtest}
        dataSoal={props.dataSoal}
        soalPetunjuk={props.soalPetunjuk}
        waktu={props.waktu}
        customLayout={props.customLayout}
      />
    </div>
  )
}

export default index
