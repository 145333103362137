import React, { useState, useEffect } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  PaginationLink,
  PaginationItem,
} from "reactstrap"
import index from "."
import jwtDecode from "common/TokenJwt/jwt-decode"
import { json, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"
import HistorySoal from "components/HistorySoal/history_soal"
import { set } from "lodash"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
const MemoizedCountdownTimer = React.memo(CountdownTimer);

const TipeB = props => {
  const { data } = props
  const DecodeJwt = jwtDecode(localStorage.getItem("access_token"))

  const idSubtest = props.idSubtest
  const dataSoal = props.dataSoal
  const soalPetunjuk = props.soalPetunjuk
  const customLayout = props.customLayout

  const navigate = useNavigate()
  // Initialize the state with the initial data
  const [questions, setQuestions] = useState([])
  const [kunciArray, setKunciArray] = useState([])
  const [jawaban, setJawaban] = useState([])
  const [kondisiSoal, setkondisiSoal] = useState()
  const [dataPage, setDataPage] = useState()
  const [statusTImer, setStatusTimer] = useState(false)

  const itemsPerPage = 1 // Ganti jumlah item per halaman sesuai kebutuhan
  const [page, setPage] = useState(1)

  const totalPage = Math.ceil(dataPage / itemsPerPage)

  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage
  const isLastPage = page === totalPage

  const onDragEnd = result => {
    if (!result.destination) return

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    // Ensure the drag is within the same question
    if (result.type === "pilihan_jawaban") {
      const updatedQuestions = [...questions]
      const questionIndex = result.source.droppableId
      const sourceQuestion = updatedQuestions[questionIndex]
      const [draggedItem] = sourceQuestion.pilihan_jawaban.splice(
        sourceIndex,
        1
      )
      sourceQuestion.pilihan_jawaban.splice(destinationIndex, 0, draggedItem)

      setQuestions(updatedQuestions)

      console.log(updatedQuestions)

      // Update kunciArray along with questions
      const updatedKunciArray = [...kunciArray]
      updatedKunciArray[questionIndex] = sourceQuestion.pilihan_jawaban.map(
        answer => answer.kunci
      )
      console.log(data)
      const checkKunci = JSON.parse(data[destinationIndex].kunci_urutan)
      console.log('chekkunci' + checkKunci)
      const kunciString = updatedKunciArray[questionIndex].join(",")

      // Find the index of the matching item in 'checkKunci'
      const indexInCheckKunci = checkKunci.indexOf(kunciString)
      console.log(checkKunci)

      if (indexInCheckKunci !== -1) {
        const checkKunci = JSON.parse(data[destinationIndex].value_urutan)
        setJawaban(prevData => {
          const jawabanArray = [...prevData]
          jawabanArray[destinationIndex] = {
            id_msoal: data[destinationIndex].id_msoal,
            soal: data[destinationIndex].soal,
            value: checkKunci[indexInCheckKunci],
            id_soal_jawaban: null,
            no_soal: data[destinationIndex].no_soal,
            pilihan: kunciString,
            ju_id_indikator_jawaban: data[destinationIndex].id_indikator
          }
          return jawabanArray
        })
      } else {
        setJawaban(prevData => {
          const jawabanArray = [...prevData]
          jawabanArray[destinationIndex] = {
            id_msoal: data[destinationIndex].id_msoal,
            soal: data[destinationIndex].soal,
            value: "0",
            id_soal_jawaban: null,
            no_soal: data[destinationIndex].no_soal,
            pilihan: kunciString,
            ju_id_indikator_jawaban: data[destinationIndex].id_indikator
          }
          return jawabanArray
        })
      }
    }
  }

  const HandlleNextPageAndSaveJawban = value => {
    setPage(value)
  }

  const saveJawaban = () => {
  }

  const saveAllData = () => {
  }

  const handleSaveJawaban = () => {
    saveAllData()
  }
  const handleSaveJawabanPetunjuk = () => {
    setJawaban([])
    setkondisiSoal(false)
    setPage(1)
  }
  useEffect(() => {
    if (soalPetunjuk.length > 0) {
      setkondisiSoal(true)
      setDataPage(soalPetunjuk.length)
      setQuestions(soalPetunjuk)
    } else {
      setkondisiSoal(false)
      setDataPage(dataSoal.length)
      setQuestions(dataSoal)
    }
  }, [kondisiSoal])

  useEffect(() => {
    if (statusTImer === true) {
      handleSaveJawaban()
    }
  }, [statusTImer])

  const customLayoutCardSoal = {
    backgroundColor: customLayout?.bgcolor_soal,
  }

  const customLayoutSoal = {
    fontFamily: customLayout?.jenis_font_soal,
    color: customLayout?.textcolor_soal,
    textAlign: customLayout?.textalign_soal,
  };

  const customLayoutCardJawaban = {
    backgroundColor: customLayout?.bgcolor_jawaban,
  }

  const customLayoutJawaban = {
    fontFamily: customLayout?.jenis_font_jawaban,
    color: customLayout?.textcolor_jawaban,
    textAlign: customLayout?.textalign_jawaban,
  };

  return (
    <div>
      <Row>
        <div className="alert alert-success w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            {kondisiSoal
              ? "Berikut Adalah petunjuk soal mengurutkan, tahan dan tarik keatas atau kebawah card untuk mengurutkan jawaban"
              : "Pastikan anda mengerjakan semua soal"}
          </p>
        </div>
        {props.waktu && kondisiSoal === false && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p className="fs-5 fw-medium ms-3 text-center" style={{ lineHeight: "1" }}>
              <MemoizedCountdownTimer durationInMinutes={props.waktu} setStatusTimer={setStatusTimer} />
            </p>
          </div>
        )}
      </Row>
      <div className="mt-3">
        <hr size="5" width="100%" className="bg-light" />
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        {questions &&
          questions.slice(startIndex, endIndex).map((item, index) => (
            <Row key={item.id_msoal} className="my-4">
              <Col xl={10}>
                <Card
                  className="shadow shadow-lg"
                  style={customLayout != null ? customLayoutCardSoal : null}
                >
                  <CardBody>
                    <p
                      style={customLayout != null ? customLayoutSoal : null}
                      className="fs-5 fw-medium"
                      dangerouslySetInnerHTML={{
                        __html: item.soal,
                      }}
                    ></p>
                    <div className="mt-3">
                      <hr size="5" width="100%" className="bg-light" />
                    </div>
                    <Droppable
                      droppableId={(startIndex + index).toString()}
                      type="pilihan_jawaban"
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {item.hasOwnProperty("pilihan_jawaban") &&
                            item.pilihan_jawaban.map((answer, answerIndex) => (
                              <Card
                                key={answer.id_soal_jawaban}
                                className="bg-light shadow shadow-sm"
                              >
                                <CardBody>
                                  <Draggable
                                    draggableId={answer.id_soal_jawaban.toString()}
                                    index={answerIndex}
                                    style={customLayout != null ? customLayoutCardJawaban : null}
                                  >
                                    {provided => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        <p
                                          style={customLayout != null ? customLayoutJawaban : null}
                                          className="fs-6 fw-semibold"
                                          dangerouslySetInnerHTML={{
                                            __html: answer.pilihan,
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                </CardBody>
                              </Card>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </CardBody>
                </Card>
                {/* <Row>
                  <Col lg="12">
                    <ul className="pagination pagination-rounded justify-content-center mt-2 mb-5">
                      <PaginationItem disabled={page === 1}>
                        <PaginationLink
                          previous
                          href="#"
                          onClick={() => HandlleNextPageAndSaveJawban(page - 1)}
                        />
                      </PaginationItem>

                      {Array.from({ length: totalPage }).map((_, i) => (
                        <PaginationItem active={i + 1 === page} key={i}>
                          <PaginationLink
                            onClick={() => HandlleNextPageAndSaveJawban(i + 1)}
                            href="#"
                          >
                            {i + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}

                      <PaginationItem disabled={page === totalPage}>
                        <PaginationLink
                          next
                          href="#"
                          onClick={() => HandlleNextPageAndSaveJawban(page + 1)}
                        />
                      </PaginationItem>
                    </ul>
                  </Col>
                </Row> */}
              </Col>
              <Col xl={2}>
                <HistorySoal
                  data={kondisiSoal ? soalPetunjuk : dataSoal}
                  selectedOptions={jawaban}
                  setSoal={setPage}
                />
              </Col>
            </Row>
          ))}
      </DragDropContext>

      {/* {isLastPage && (
        <Row>
          <Col xl={12} style={{ marginTop: "10vh" }}>
            <Card className="shadow shadow-lg">
              <CardBody className="text-end">
                {kondisiSoal ? (
                  <button
                    className="btn-primary btn rounded rounded-3"
                    onClick={() => handleSaveJawabanPetunjuk()}
                  >
                    Mulai Kerjakan
                  </button>
                ) : (
                  <button
                    className="btn-primary btn rounded rounded-3"
                    onClick={() => handleSaveJawaban()}
                  >
                    Simpan Jawaban
                  </button>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      )} */}
    </div>
  )
}

export default TipeB
