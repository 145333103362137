import React, { useEffect, useState } from 'react'
import Navbar from "components/HorizontalLayout/Navbar"
import ProjectsGrid from './projects-grid'
import Loading from 'components/Loading/loading';
import { useParams } from 'react-router-dom';
import jwtDecode from 'common/TokenJwt/jwt-decode';


const Proktoring = () => {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const { nip, pass, idJadwal } = useParams();

    useEffect(() => {
        var formdata = new FormData();
        formdata.append("nip", nip);
        formdata.append("password", pass);
        formdata.append("idJadwal", idJadwal);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "auth/jumper"
        fetch(baseURL, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.meta.code == 200) {
                    localStorage.setItem('access_token', data.data.access_token)
                    localStorage.setItem('expired_at', data.data.expires_at);
                    const decodeToken = jwtDecode(localStorage.getItem("access_token"))
                    console.log(data.data.access_token)
                    console.log(decodeToken)
                    setData(decodeToken.id_jadwal)
                    setLoading(false)
                }
            })
            .catch(error => console.log('error', error));
    }, [])
    console.log(data)
    return (
        <React.Fragment>
            {data && loading === false ? (
                <ProjectsGrid idJadwal={data} />
            ) : (
                <Loading />
            )}
        </React.Fragment>
    )
}

export default Proktoring