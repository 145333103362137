import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import bgLogin from "../../assets/images/bg-login-new.svg"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

//Social Media Imports
import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"

//Import config
import { facebook, google } from "../../config"
import Swal from "sweetalert2"
const Login = props => {
  //meta title
  document.title = "Login"
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const url = new URLSearchParams(window.location.search)
  const nip = url.get('nip')
  const noreg = url.get('noreg')
  const password = "0189713"

  useEffect(()=> {
    console.log(nip)
    if(nip != null){
    const formdata = new FormData()

    formdata.append("nip", nip)
    formdata.append("password", password)
    formdata.append("noreg", noreg)

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "auth/login"
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.meta.code == 200) {
          localStorage.setItem("access_token", data.data.access_token)
          localStorage.setItem("expired_at", data.data.expires_at)
          localStorage.setItem("password_ukom", "0189713")
          navigate(`/dashboard`)
        }
      })
      .catch(error => {
        console.log("error", error)
        Swal.fire({
          title: "Error",
          text: "An error occurred while logging in. Please try again later.",
          icon: "error",
        })
      })
    }
    }, [])
    
  localStorage.clear();

  const handleSubmit = values => {
    Swal.fire({
      title: "Loading...",
      text: "Please wait while we process your request",
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })

    const formdata = new FormData()
    formdata.append("nip", values.nip)
    formdata.append("password", values.password)
    formdata.append("noreg", values.noreg)

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    const baseURL = process.env.REACT_APP_BACKEND_URL_VERSION + "auth/login"
    fetch(baseURL, requestOptions)
      .then(response => response.json())
      .then(data => {
        // console.log(data);
        if (data.meta.code == 200) {
          localStorage.setItem("access_token", data.data.access_token)
          localStorage.setItem("expired_at", data.data.expires_at)
          localStorage.setItem("proctoring_status", data.data.proctoring)
          console.log()
          Swal.fire({
            title: "Success!",
            text: "You have been logged in.",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
          }).then(() => {
            if (data.data.proctoring == 'off') {
              navigate(`/dashboard`)
            } else {
              navigate(`/capture`)
            }
          })
        } else {
          Swal.fire({
            title: "Error",
            text: data.data.error,
            icon: "error",
          })
        }
      })
      .catch(error => {
        console.log("error", error)
        Swal.fire({
          title: "Error",
          text: "An error occurred while logging in. Please try again later.",
          icon: "error",
        })
      })
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      nip: "",
      noreg: "",
      password: "",
    },
    validationSchema: Yup.object({
      nip: Yup.string().required("Please Enter Your nip"),
      password: Yup.string().required("Please Enter Your Password"),
      noreg: Yup.string().required("Please Enter Your kode jadwal"),
    }),
    onSubmit: handleSubmit,
  })

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Card className="shadow shadow-lg rounded-4 bg-primary">
              <CardBody className="p-0 m-0">
                <Row className="align-items-center">
                  <Col xl={7} lg={6} md={8} className="position-relative">
                    <img
                      src={bgLogin}
                      style={{
                        display: "none", // Hide the image on smaller screens
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -35%)",
                        zIndex: "1",
                        width: "60rem",
                      }}
                      alt="Background"
                      className="d-none d-md-block d-lg-block"
                    />
                  </Col>
                  <Col md={4} lg={6} xl={5} className="bg-light rounded-end" style={{ zIndex: "2" }}>
                    <Card className="overflow-hidden bg-transparent">
                      <div className="bg-light">
                        <Row>
                          <Col xs={7}>
                            <div className="text-primary p-4">
                              <h5 className="text-primary">Welcome Back !</h5>
                              <p>Sign in to continue UTT</p>
                            </div>
                          </Col>
                          <Col className="col-5 align-self-end">
                            <img src={profile} alt="" className="img-fluid" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <Form
                            className="form-horizontal"
                            onSubmit={validation.handleSubmit}
                          >
                            {error ? (
                              <Alert color="danger">{error}</Alert>
                            ) : null}

                            <div className="mb-3">
                              <Label className="form-label">NIP</Label>
                              <Input
                                name="nip"
                                className="form-control"
                                placeholder="Enter NIP"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.nip || ""}
                                invalid={
                                  validation.touched.nip &&
                                    validation.errors.nip
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.nip &&
                                validation.errors.nip ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.nip}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">Kode Jadwal</Label>
                              <Input
                                name="noreg"
                                className="form-control"
                                placeholder="Enter jadwal code"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.noreg || ""}
                                invalid={
                                  validation.touched.noreg &&
                                    validation.errors.noreg
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.noreg &&
                                validation.errors.noreg ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.noreg}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">Password</Label>
                              <Input
                                name="password"
                                type="password"
                                placeholder="Enter Password"
                                value={validation.values.password || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                    validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                                validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Log In
                              </button>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
