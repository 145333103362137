import React, { useState, useEffect } from "react"
import soal_inbasketemail from "../SoalInBasket"
import list_sosmed from "../SoalInBasket/list_sosmed"
import SoalEmail from "./TipeSoal/multiple_choice"
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  Collapse,
  CardHeader,
} from "reactstrap"
import email from "../../../../src/assets/images/sosial_media/email.svg"
import memo from "../../../../src/assets/images/sosial_media/memo.png"
import whatsapp from "../../../../src/assets/images/sosial_media/Whatsapp.svg"
import instagram from "../../../../src/assets/images/sosial_media/Instagram.png"
import facebook from "../../../../src/assets/images/sosial_media/Facebook.svg"
import twitter from "../../../../src/assets/images/sosial_media/Twitter.svg"
import kalender from "../../../../src/assets/images/sosial_media/kalender.svg"
import other from "../../../../src/assets/images/sosial_media/other.svg"

import index from "."
import MultipleChoice from "../SoalMultipleChoice/tipeA"
import Matrix from "../SoalMatrixQuestion/tipeA"
import CountdownTimer from "components/CountdownTimer/CountdownTimer"
import classnames from "classnames"
const MemoizedCountdownTimer = React.memo(CountdownTimer)

const Inbasket = ({
  idLayout,
  data,
  skenario,
  idAlattest,
  idSubtest,
  jenisSoal,
  listSosmed,
  dataSoal,
  soalPetunjuk,
  waktu,
  customLayout,
  subtest,
}) => {
  const [sosmed, setSosmed] = useState()
  //menggabungkan sosmed yang sama
  const margePaketSosmed = [
    ...new Set(listSosmed.map(item => item.sosial_media)),
  ]

  const filteredData = margePaketSosmed.map(sosial_media => {
    return listSosmed.find(item => item.sosial_media === sosial_media)
  })

  const subTest = subtest
  const [kondisiSoal, setkondisiSoal] = useState()
  const [dataPage, setDataPage] = useState()
  const [openAccordions, setOpenAccordions] = useState([])
  const [statusTImer, setStatusTimer] = useState(false)
  const [showInstruksi, setShowInstruksi] = useState(true)
  const [showTimer, setShowTimer] = useState(true)
  const [openAccordionsInstruksi, setOpenAccordionsInstruksi] = useState(true)

  const toggleAccordion = index => {
    const updatedOpenAccordions = [...openAccordions]
    updatedOpenAccordions[index] = !updatedOpenAccordions[index]
    setOpenAccordions(updatedOpenAccordions)
  }

  useEffect(() => {
    const sosmedView = filteredData[0].sosial_media
    setSosmed(sosmedView)
  }, [])

  // useEffect(() => {
  //   if (soalPetunjuk.length > 0) {
  //     setkondisiSoal(true)
  //     setDataPage(soalPetunjuk.length)
  //     setdata(soalPetunjuk)
  //   } else {
  //     setkondisiSoal(false)
  //     setDataPage(dataSoal.length)
  //     setdata(dataSoal)
  //   }
  // }, [kondisiSoal])

  const toggleAccordionInstruksi = () => {
    setOpenAccordionsInstruksi(openAccordionsInstruksi === true ? false : true)
  }

  const [soalState, setSoalState] = useState(6)
  const [isExpanded, setIsExpanded] = useState(false)
  const [petunjukStatus, setPetunjukStatus] = useState(true)

  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded)
  }

  const toggleInstruksiVisibility = () => {
    setShowInstruksi(!showInstruksi)
  }

  const toggleTimerVisibility = () => {
    setShowTimer(!showTimer)
  }

  return (
    <div>
      <Row>
        <div className="alert alert-primary w-100 d-flex align-items-center">
          <p className="fs-5 fw-medium ms-3" style={{ lineHeight: "1" }}>
            <i className="mdi mdi-alert-circle" style={{ lineHeight: "1" }}></i>{" "}
            Soal bentuk {jenisSoal}
          </p>
        </div>
        <div className="accordion m-3" id="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={classnames("accordion-button", "fw-medium", {
                  collapsed: !openAccordionsInstruksi,
                })}
                type="button"
                onClick={() => toggleAccordionInstruksi()}
                style={{ cursor: "pointer" }}
              >
                Instruksi
              </button>
            </h2>

            <Collapse
              isOpen={openAccordionsInstruksi}
              className="accordion-collapse"
            >
              <div className="accordion-body">
                <CardBody className="bg-light rounded rounded-3">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: subTest?.instruksi,
                    }}
                  />
                </CardBody>
              </div>
            </Collapse>
          </div>
        </div>
        {waktu && (
          <div className="alert alert-primary d-flex align-items-center justify-content-center">
            <p
              className="fs-5 fw-medium ms-3 text-center"
              style={{ lineHeight: "1" }}
            >
              <MemoizedCountdownTimer
                durationInMinutes={waktu}
                setStatusTimer={setStatusTimer}
              />
            </p>
          </div>
        )}
      </Row>
      <Row>
        <Col xl={1}>
          <Card className="bg-transparent border border-primary shadow shadow-lg">
            <CardBody className="text-center ms-3">
              {filteredData.map((item, index) => (
                <a
                  key={index}
                  className="btn btn-transparant  me-3"
                  role="button"
                  onClick={() => setSosmed(item.sosial_media)}
                >
                  <img
                    className="email"
                    src={
                      item.sosial_media === "Wa"
                        ? whatsapp
                        : item.sosial_media === "Memo"
                        ? memo
                        : item.sosial_media === "Twitter"
                        ? twitter
                        : item.sosial_media === "Email"
                        ? email
                        : item.sosial_media === "Facebook"
                        ? facebook
                        : item.sosial_media === "Instagram"
                        ? instagram
                        : item.sosial_media === "Kalender"
                        ? kalender
                        : item.sosial_media === "Lainnya"
                        ? other
                        : ""
                    }
                    alt={item.sosial_media}
                  />
                </a>
              ))}
            </CardBody>
          </Card>
        </Col>
        {/* Sosial Media Card */}
        <Col xl={isExpanded ? 5 : 4}>
          <Card className="bg-transparent border border-primary shadow shadow-lg">
            <CardHeader className="bg-primary">
              <label className="fs-5 fw-semibold text-white">
                Sosial Media : {sosmed}
              </label>
            </CardHeader>
            <CardBody>
              {listSosmed.map((item, index) => (
                <div key={index}>
                  {item.sosial_media === sosmed && (
                    <>
                      <div className="accordion m-3" id="accordion">
                        <div className="accordion-item" key={index}>
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className={classnames(
                                "accordion-button",
                                "fw-medium",
                                { collapsed: !openAccordions[index] }
                              )}
                              type="button"
                              onClick={() => toggleAccordion(index)}
                              style={{ cursor: "pointer" }}
                            >
                              {item.judul}
                            </button>
                          </h2>

                          <Collapse
                            isOpen={openAccordions[index]}
                            className="accordion-collapse"
                          >
                            <div className="accordion-body">
                              <CardBody className="bg-light rounded rounded-3">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.isi,
                                  }}
                                />
                              </CardBody>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>
        {/* Card Soal */}
        <Col xl={isExpanded ? 6 : 7}>
          <Card className="bg-transparent border border-primary shadow shadow-lg">
            <CardBody>
              {idLayout === 1 ||
              idLayout === 2 ||
              idLayout === 3 ||
              idLayout === 4 ? (
                <MultipleChoice
                  idAlattest={idAlattest}
                  idSubtest={idSubtest}
                  data={data}
                  dataSoal={dataSoal}
                  soalPetunjuk={soalPetunjuk}
                  jenisSoal={jenisSoal}
                  waktu={waktu}
                  customLayout={customLayout}
                  subtest={subtest}
                  showInstruksi={showInstruksi}
                  showTimer={showTimer}
                  isExpand={isExpanded} // Pass the state
                  onExpandToggle={handleExpandToggle} // Pass the callback
                />
              ) : idLayout === 6 || idLayout === 14 ? (
                <Matrix
                  idLayout={idLayout}
                  data={data}
                  dataSoal={dataSoal}
                  soalPetunjuk={soalPetunjuk}
                  skenario={skenario}
                  idAlattest={idAlattest}
                  idSubtest={idSubtest}
                  waktu={waktu}
                  customLayout={customLayout}
                  subtest={subtest}
                  showInstruksi={showInstruksi}
                  showTimer={showTimer}
                  isExpand={isExpanded} // Pass the state
                  onExpandToggle={handleExpandToggle} // Pass the callback
                />
              ) : (
                ""
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Inbasket
