import React, { useEffect, useState } from "react"

import SoalMultipleChoice from "./SoalMultipleChoice/index"
import SoalBipolar from "./SoalBipolar/index"
import SoalMatrix from "./SoalMatrixQuestion/index"
import SoalText from "./SoalText/soal_text"
import SoalBertingkat from "./SoalBei/index"
import SoalMengurtkan from "./SoalRankingQuestion/index"
import Inbasket from "./SoalInBasket/index"
import SoalJenisKompetensi from "./SoalJenisKompetansi/index"
import SoalRating from "./SoalRating/soal_rating"
import { useParams, useNavigate } from "react-router-dom"
import { Container } from "reactstrap"
import { data } from "autoprefixer"
import jwtDecode from "common/TokenJwt/jwt-decode"
import CryptoJS from "crypto-js"
import Swal from "sweetalert2"
import "../../assets/scss/custom/components/_loading.scss"
import CaptureRecords from '../../components/CaptureRecord/index'
import { io } from "socket.io-client"

const index = () => {
  const navigate = useNavigate()
  const [data, setData] = useState()
  const [soalPetunjuk, setSoalPetunjuk] = useState()
  const [soal, setSoal] = useState()
  const [idLayout, setIdLayout] = useState()
  const [nameLayout, setNameLayout] = useState()
  const [skenario, setSkenario] = useState()
  const [jenisSoal, setJenisSoal] = useState()
  const [listSosmed, setListSosmed] = useState()
  const [waktu, setWaktu] = useState()
  const [statusTimer, setStatusTimer] = useState()
  const [customLayout, setCustomLayout] = useState()
  const { id } = useParams()
  const decodeToken = jwtDecode(localStorage.getItem("access_token"))
  const id_jadwal = decodeToken.id_jadwal
  const nip = decodeToken.nip
  const id_asesi = decodeToken.id_jadwal_asesi

  const secretKey = "sonsonz"
  const decryptedBytes = CryptoJS.AES.decrypt(id, secretKey)
  const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8)
  const decryptedData = JSON.parse(decryptedText)
  const [subTest, setSubTest] = useState()

  const socket = io.connect(process.env.REACT_APP_BACKEND_SOCKET_URL, {
    transports: ["websocket"],
    path: '/socket-ac/socket.io',
    extraHeaders: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_URL,
    },
    query: {
      idJadwal: id_jadwal,
      idSubtest: decryptedData.idSubtest, // Replace with the desired user ID
      nip: nip,
      accessToken: localStorage.getItem("access_token"),
    },
  })

  socket.on('connect', () => {
    console.log('Socket connected:', socket.id);
  });
  socket.on('connect_error', (error) => {
    console.error('Connection failed:', error.message);
  });

  const confirm = () => {
    console.log("merdeka");
    Swal.fire({
      title: "WAKTU BERHENTI",
      icon: "error",
      allowOutsideClick: false,
      showConfirmButton: false,
      width: 600,
      padding: 100,
      background: "#fffff",
      html: `
        <p>Konfirmasi kepada Admin, setelah itu baru anda dapat melanjutkan pengerjaan.</p>
        <button id="reloadButton" style="margin-top: 20px; padding: 10px 20px; background-color: #d33; color: white; border: none; border-radius: 5px; cursor: pointer;">
          Kembali
        </button>
      `
    });

    // Add event listener for the custom button
    document.getElementById('reloadButton').addEventListener('click', () => {
      Swal.close();
      navigate('/dashboard');
    });
  };



  useEffect(() => {
    const customSwal = Swal.mixin({
      customClass: {
        container: "custom-swal-popup",
      },
      html: `
      <div class="ring">
      Loading
      <div class='circle'></div>
      `,
      showConfirmButton: false,
      background: "transparent", // Make the modal background transparent
      customContainerClass: "custom-modal-container", // Apply a custom class to the modal container
    })
    customSwal.fire()
    var myHeaders = new Headers()

    myHeaders.append(
      "Authorization",
      "Bearer" + localStorage.getItem("access_token")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Soal?id_subtest=" +
      decryptedData.idSubtest +
      "&id_jadwal=" +
      id_jadwal +
      "&nip=" +
      nip +
      "&id_asesi=" +
      id_asesi,
      requestOptions
    )
      .then(response => response.text())
      .then(result => {
        const responseData = JSON.parse(result)
        if (responseData.meta.code == 200) {
          console.log(responseData)
          const dataSoal = responseData.data[0].data
          setData(responseData.data[0].data)
          setIdLayout(responseData.data[0].layout.mlb_id)
          setWaktu(responseData.data[0].timer_status.time)
          setStatusTimer(responseData.data[0].timer_status)
          setNameLayout(responseData.data[0].layout.mlb_name)
          setSkenario(responseData.data[0].skenario)
          setJenisSoal(responseData.data[0].layout.jenis_soal)
          setListSosmed(responseData.data[0].list_sosmed)
          setSoalPetunjuk(dataSoal.filter(item => item.is_petunjuk === "true"))
          setSoal(dataSoal.filter(item => item.is_petunjuk === "false" || item.is_petunjuk === null))
          setCustomLayout(responseData.data[0].custom_layout)
          setSubTest(responseData.data[0].subtest[0])
          customSwal.close()
          if (responseData.data[0].timer_status.status === false) {
            confirm();
          }

          console.log(responseData.data[0].subtest[0])
        } else {
          customSwal.close();
          Swal.fire({
            title: 'Error',
            text: 'There is no question found in this subtest, please contact the admin.',
            icon: 'error',
            confirmButtonText: 'OK'
          }).then(() => {
            navigate('/dashboard');
          });
        }
      })
      .catch(error => {
        console.log(error)
        customSwal.close();
        Swal.fire({
          title: 'Error',
          text: 'There is no question found in this subtest, please contact the admin.',
          icon: 'error',
          confirmButtonText: 'OK'
        }).then(() => {
          navigate('/dashboard');
        });
      })
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      CaptureRecords()
      console.log('trst');
    }, 30000); // 30.000 milidetik = 30 detik
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // console.log(soal)

  return (
    <React.Fragment>
      {statusTimer?.status === true && (
        <div className="page-content">
          <Container fluid>
            {jenisSoal ? (
              <SoalJenisKompetensi
                idLayout={idLayout}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                skenario={skenario}
                idAlattest={decryptedData.idAlattest}
                idSubtest={decryptedData.idSubtest}
                jenisSoal={jenisSoal}
                listSosmed={listSosmed}
                waktu={waktu}
                customLayout={customLayout}
                statusTimer={statusTimer}
                subTest={subTest}
              />
            ) : idLayout === 1 || idLayout === 3 || idLayout === 4 ? (
              <SoalMultipleChoice
                idAlattest={decryptedData.idAlattest}
                idSubtest={decryptedData.idSubtest}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
                statusTimer={statusTimer}
                subTest={subTest}
              />
            ) : idLayout === 7 ? (
              <SoalText
                idAlattest={decryptedData.idAlattest}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                idSubtest={decryptedData.idSubtest}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 6 || idLayout === 14 ? (
              <SoalMatrix
                idLayout={idLayout}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                skenario={skenario}
                idAlattest={decryptedData.idAlattest}
                idSubtest={decryptedData.idSubtest}
                waktu={waktu}
                customLayout={customLayout}
                subTest={subTest}
              />
            ) : idLayout === 9 || idLayout === 10 ? (
              <SoalMengurtkan
                idAlattest={decryptedData.idAlattest}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                idSubtest={decryptedData.idSubtest}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 13 ? (
              <SoalBertingkat
                idAlattest={decryptedData.idAlattest}
                idSubtest={decryptedData.idSubtest}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 5 ? (
              <SoalBipolar
                idAlattest={decryptedData.idAlattest}
                idSubtest={decryptedData.idSubtest}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : idLayout === 2 ? (
              <SoalRating
                idAlattest={decryptedData.idAlattest}
                idSubtest={decryptedData.idSubtest}
                data={data}
                dataSoal={soal}
                soalPetunjuk={soalPetunjuk}
                waktu={waktu}
                customLayout={customLayout}
              />
            ) : (
              ""
            )}
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

export default index
