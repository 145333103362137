import { Container, Row, Col, CardBody, Card, Button } from "reactstrap"
import TipeA from "./tipeA"

import React, { useState, useEffect } from "react"
const index = ({
  idAlattest,
  idSubtest,
  data,
  jenisSoal,
  caseId,
  setUrutanCase,
  urutanCase,
  jumlahCase,
  setOptions,
  dataSoal,
  soalPetunjuk,
  waktu,
  customLayout,
  subTest
}) => {
  return (
    <div>
      <TipeA
        idAlattest={idAlattest}
        idSubtest={idSubtest}
        data={data}
        jenisSoal={jenisSoal}
        caseId={caseId}
        setUrutanCase={setUrutanCase}
        urutanCase={urutanCase}
        jumlahCase={jumlahCase}
        setOptions={setOptions}
        dataSoal={dataSoal}
        soalPetunjuk={soalPetunjuk}
        waktu={waktu}
        customLayout={customLayout}
        subTest={subTest}
      />
    </div>
  )
}

export default index
